import { WoocommerceService } from './../../../services/woocommerce.service';
import { Component, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/services/notification.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-woocommerce-update-product',
  templateUrl: './woocommerce-update-product.component.html',
  styleUrls: ['./woocommerce-update-product.component.scss']
})
export class WoocommerceUpdateProductComponent implements OnInit {

  constructor(
    public woocommerceService: WoocommerceService, 
    public dialogRef: MatDialogRef<WoocommerceUpdateProductComponent>,
    public notificationService: NotificationService
    ) { }

  ngOnInit(): void {
  }
  onClear() {
    this.woocommerceService.editProductForm.reset();
    this.woocommerceService.initializeEditProductForm();
    this.notificationService.success('Updated Product data submitted successfully');
  }

  onClose() {
    this.woocommerceService.editProductForm.reset();
    this.dialogRef.close();
  }

  onSubmit() {
    this.woocommerceService.editProduct(this.woocommerceService.editProductForm.value);
    this.woocommerceService.editProductForm.reset();
    this.notificationService.success('Updated Product data submitted successfully');
    this.onClose();
  }

}
