import { StatsService } from './../../../services/stats.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-income-statement',
  templateUrl: './income-statement.component.html',
  styleUrls: ['./income-statement.component.scss']
})
export class IncomeStatementComponent implements OnInit {

  constructor(public statsService: StatsService) { }

  ngOnInit(): void {
  }
  getIncomeStatement(start: string, end: string){
    this.statsService.getIncomeStatement(start, end);
  }

}
