import { ContractService } from './../../../services/contract.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { fullContract } from 'src/app/models/fullContract.model';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-generate-contract-receipt',
  templateUrl: './generate-contract-receipt.component.html',
  styleUrls: ['./generate-contract-receipt.component.scss']
})
export class GenerateContractReceiptComponent implements OnInit {

  contract!: fullContract;
  id!: any;

  constructor(public contractService: ContractService, private route: ActivatedRoute, private router: Router) { }
  printPage() {
    window.print();
  }
  
  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get("id")
    console.log(this.id);
    this.contractService.getContract(this.id).subscribe(
      response => {
        console.log(response);
        this.contract = response;
      }
    )
  }
  getDate(d: string): any {
    const datepipe: DatePipe = new DatePipe("de-DE")
    let formattedDate = datepipe.transform(d, 'dd.MM.YYYY');
    console.log(formattedDate)
    return formattedDate
  }

}
