<br>
<a href="/woocommerce-products" mat-raised-button color="primary">
    &nbsp; <mat-icon>shopping_bag</mat-icon>&nbsp;Manage Woocommerce Products
</a>
<br>
<br>
<h2>List of all Woocommerce Orders</h2>
<br>
<i class="fas fa-file-contract"></i>
<table mat-table [dataSource]="woocommerceService.orders" class="mat-elevation-z8">

    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

    <!-- order number Column -->
    <ng-container matColumnDef="number">
        <th mat-header-cell *matHeaderCellDef> Order Number &nbsp;</th>
        <td mat-cell *matCellDef="let order"> {{order.number}} &nbsp;&nbsp;</td>
    </ng-container>

    <!-- date Column -->
    <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef> Order Date &nbsp;</th>
        <td mat-cell *matCellDef="let order"> {{dateService.formatDate(order.date_created_gmt)}} &nbsp;&nbsp;</td>
    </ng-container>

    <!-- amount Column -->
    <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef> Order amount &nbsp;</th>
        <td mat-cell *matCellDef="let order"> {{order.total}}€&nbsp;&nbsp;</td>
    </ng-container>

    <!-- status Column -->
    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef> Status &nbsp;</th>
        <td mat-cell *matCellDef="let order"> {{order.status}}&nbsp;&nbsp;</td>
    </ng-container>

    <!-- action Column -->
    <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef> View Order </th>
        <td mat-cell *matCellDef="let order">
            <a href="/woocommerce-order-details/{{order.id}}" mat-raised-button color="primary">
                &nbsp; <mat-icon>visibility</mat-icon>&nbsp;View Order
            </a>
        </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>