import { IncomeStatement } from './../models/incomeStatement.model';
import { WoocommerceProduct } from './../models/woocommerceProduct.model';
import { WoocommerceOrder } from './../models/woocommerceOrder';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoginService } from './login.service';
import { environment } from 'src/environments/environment';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class StatsService {
  
  incomeStatement!: IncomeStatement

  constructor(public httpClient: HttpClient, public loginService: LoginService) { }

  getIncomeStatement(start: string, end: string) {
    console.log("requesting income statement with start: "+start+" and end: "+end)
    this.httpClient.get<IncomeStatement>(this.loginService.getBackendUrl() + '/statistics/incomestatement/range?start='+start+'&end='+end, { headers: this.loginService.getAuthHeader() }).subscribe(
      response => {
        console.log(response);
        this.incomeStatement = response;
      }
    );
  }
}