<br>
<h2>Unpaid Invoices</h2>
<i class="fas fa-file-contract"></i>
<div *ngIf="invoiceService.unpaidInvoices != []">
    <table mat-table [dataSource]="invoiceService.unpaidInvoices" class="mat-elevation-z8">

        <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

        <!-- Date Column -->
        <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef> Date </th>
            <td mat-cell *matCellDef="let invoice"> {{dateService.formatDate(invoice.invoiceDate)}} &nbsp;&nbsp;</td>
        </ng-container>


        <!-- Customer Column -->
        <ng-container matColumnDef="customer">
            <th mat-header-cell *matHeaderCellDef> Customer </th>
            <td mat-cell *matCellDef="let invoice">
                <a href="customer-details/{{invoice.customer.id}}">
                    {{invoice.customer.firstName}} {{invoice.customer.lastName}}
                </a>&nbsp;&nbsp;
            </td>
        </ng-container>

        <!-- Invoice Column -->
        <ng-container matColumnDef="invoice">
            <th mat-header-cell *matHeaderCellDef> Invoice </th>
            <td mat-cell *matCellDef="let invoice">
                <a href="/generate-invoice/{{invoice.invoice.id}}" mat-raised-button color="primary">
                    <mat-icon>receipt</mat-icon>&nbsp; View Invoice
                </a>&nbsp;
            </td>
        </ng-container>

        <!-- Reminder email Column -->
        <ng-container matColumnDef="reminder">
            <th mat-header-cell *matHeaderCellDef> Send Payment Reminder </th>
            <td mat-cell *matCellDef="let invoice">
                <button mat-raised-button (click)="sendPaymentReminder(invoice.invoice.id)">
                    <mat-icon>email</mat-icon>&nbsp; Send Payment reminder
                </button>
            </td>
        </ng-container>

        <!-- mark paid Column -->
        <ng-container matColumnDef="markPaid">
            <th mat-header-cell *matHeaderCellDef> Mark as paid</th>
            <td mat-cell *matCellDef="let invoice">
                <button mat-raised-button (click)="markPaid(invoice.invoice.id)">
                    <mat-icon>price_check</mat-icon>&nbsp; Mark invoice as Paid
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>
<div *ngIf="invoiceService.unpaidInvoices">
    <br>
    <h3>All invoices are paid!</h3>
</div>