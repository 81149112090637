import { EbayAuthUrl } from './../models/ebayAuthUrl.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EbayToken } from '../models/ebayToken';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class EbayService {

  constructor(public httpClient: HttpClient, public loginService: LoginService) { }

  navigateToAuthUrl() {
    this.httpClient.get<EbayAuthUrl>(this.loginService.getBackendUrl() + '/ebay/getAuthUrl').subscribe(
      response => {
        console.log(response.url);
        document.location.href = response.url;
      }
    );
  }

  getToken(code: string) {
    this.httpClient.get<EbayToken>(this.loginService.getBackendUrl() + '/ebay/getToken/' + code).subscribe(
      response => {
        console.log(response);
        localStorage.setItem('ebayToken', response.token)
        localStorage.setItem('ebayTokenExpires', response.expires)
      }
    );
  }
}