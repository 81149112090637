<br>
<h1>User Manager</h1>
<br>
<button mat-raised-button (click)="openAddNewUser()">
    <mat-icon>add</mat-icon>&nbsp; Add new user
</button>
<br>
<br>
<h2>List of Managed Users</h2>
<i class="fas fa-file-contract"></i>
<table mat-table [dataSource]="loginService.users" class="mat-elevation-z8">

    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

    <!-- Description Column -->
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let user"> {{user.name}} &nbsp;&nbsp;</td>
    </ng-container>

    <!-- Price Column -->
    <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef> Email </th>
        <td mat-cell *matCellDef="let user"> <a href="mailto:{{user.email}}">{{user.email}}</a> &nbsp;&nbsp;</td>
    </ng-container>

    <!-- Update Column -->
    <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef> Remove </th>
        <td mat-cell *matCellDef="let user">
            <button mat-stroked-button color="warn" (click)="resetPassword(user)">
                <mat-icon>restart_alt</mat-icon>&nbsp; Reset Password
            </button>
            &nbsp;
            <button mat-raised-button color="warn" (click)="removeUser(user)">
                <mat-icon>delete</mat-icon>&nbsp; Remove user
            </button>
            
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>