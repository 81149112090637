import { Customer } from './../../../models/customer.model';
import { NotificationService } from './../../../services/notification.service';
import { CustomerService } from './../../../services/customer.service';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-new-customer',
  templateUrl: './new-customer.component.html',
  styleUrls: ['./new-customer.component.scss']
})
export class NewCustomerComponent implements OnInit {

  constructor(
    public CustomerService: CustomerService, 
    public dialogRef: MatDialogRef<NewCustomerComponent>,
    public notificationService: NotificationService
    ) { }

  ngOnInit(): void {
  }
  onClear() {
    this.CustomerService.form.reset();
    this.CustomerService.initializeFormGroup();
    this.notificationService.success('Customer Data submitted successfully');
  }

  onClose() {
    this.CustomerService.form.reset();
    this.CustomerService.initializeFormGroup();
    this.dialogRef.close();
  }

  onSubmit() {
    this.CustomerService.addCustomer(this.CustomerService.form.value);
    this.CustomerService.form.reset();
    this.CustomerService.initializeFormGroup();
    this.notificationService.success('Customer data submitted successfully');
    this.onClose();
  }
}