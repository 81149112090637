import { LoginService } from './../../services/login.service';
import { NotificationService } from './../../services/notification.service';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-new-customer',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(
    public LoginService: LoginService,
    public dialogRef: MatDialogRef<LoginComponent>,
    public notificationService: NotificationService
  ) { }

  ngOnInit(): void {
  }
  onClear() {
    this.LoginService.loginForm.reset();
    this.LoginService.initializeFormGroup();
    this.notificationService.success('Login Data submitted successfully');
  }

  onClose() {
    this.LoginService.loginForm.reset();
    this.LoginService.initializeFormGroup();
    this.dialogRef.close();
  }

  onSubmit() {
    this.LoginService.login(this.LoginService.loginForm.value);
    this.LoginService.loginForm.reset();
    this.LoginService.initializeFormGroup();
    this.notificationService.success('Login data submitted successfully - Please wait for page to reload');
    this.onClose();
  }
}