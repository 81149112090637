import { DateService } from './../../../services/date.service';
import { PayrollService } from './../../../services/payroll.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FullPayroll } from 'src/app/models/fullPayroll.model';

@Component({
  selector: 'app-generate-payroll',
  templateUrl: './generate-payroll.component.html',
  styleUrls: ['./generate-payroll.component.scss']
})
export class GeneratePayrollComponent implements OnInit {

  payroll!: FullPayroll;

  id!: any;

  constructor(public payrollService: PayrollService, private route: ActivatedRoute, private router: Router, public dateService: DateService) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get("id")
    console.log(this.id);
    this.payrollService.getPayroll(this.id).subscribe(
      response => {
        console.log(response);
        this.payroll = response;
      }
    )
  }
}
