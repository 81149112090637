<mat-toolbar>
    <span>Create Contract for existing Customer</span>
    <span class="fill-remaining-space"></span>
    <button class="btn-dialog-close" mat-stroked-button (click)="onClose()" tabIndex="-1">
        <mat-icon>clear</mat-icon>
    </button>
</mat-toolbar>
<mat-dialog-content>
    <form [formGroup]="contractService.existingCustomerForm" (ngSubmit)="onSubmit()">
        <br>
        <mat-form-field>
            <input matInput placeholder="Manufacturer" formControlName="manufacturer">
        </mat-form-field>
        <br>
        <mat-form-field>
            <input matInput placeholder="Model" formControlName="model">
        </mat-form-field>
        <br>
        <mat-form-field>
            <input matInput placeholder="IMEI / Serial" formControlName="imei">
        </mat-form-field>
        <br>
        <mat-form-field>
            <input matInput placeholder="Information" formControlName="information">
        </mat-form-field>
        <br>

        <button mat-raised-button color="primary" type="submit"
            [disabled]="contractService.existingCustomerForm.invalid">
            <mat-icon>send</mat-icon>&nbsp; Submit Contract
        </button>
    </form>
</mat-dialog-content>