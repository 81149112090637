import { UploadInvoiceComponent } from './upload-invoice/upload-invoice.component';
import { IncomingInvoiceService } from './../../services/incoming-invoice.service';
import { Component, OnInit } from '@angular/core';
import { DateService } from 'src/app/services/date.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-incoming-invoice',
  templateUrl: './incoming-invoice.component.html',
  styleUrls: ['./incoming-invoice.component.scss']
})
export class IncomingInvoiceComponent implements OnInit {

  displayedColumns: string[] = ['date', 'info', 'amount', 'tax', 'file'];

  constructor(public IncomingInvoiceService: IncomingInvoiceService, public dateService: DateService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.IncomingInvoiceService.getIncomingInvoices();
  }
  onCreate() {
    this.dialog.open(UploadInvoiceComponent);
  }

}
