<mat-toolbar>
    <span>Edit Woocommerce Product</span>
    <span class="fill-remaining-space"></span>
    <button class="btn-dialog-close" mat-stroked-button (click)="onClose()" tabIndex="-1">
        <mat-icon>clear</mat-icon>
    </button>
</mat-toolbar>
<mat-dialog-content>
    <form [formGroup]="woocommerceService.editProductForm" (ngSubmit)="onSubmit()">
        <br>
        <input type="hidden" formControlName="id">
        <input type="hidden" formControlName="premalink">
        <input type="hidden" formControlName="description">
        <mat-form-field>
            <input matInput placeholder="Slug" formControlName="slug">
        </mat-form-field>
        <br>
        <mat-form-field>
            <input matInput placeholder="Short Description" formControlName="short_description">
        </mat-form-field>
        <br>
        <mat-form-field>
            <input matInput placeholder="Price" formControlName="price">
        </mat-form-field>
        <br>
        <mat-form-field>
            <input matInput placeholder="Regular Price" formControlName="regular_price">
        </mat-form-field>
        <br>
        <mat-form-field>
            <input matInput placeholder="Sale Price" formControlName="sale_price">
        </mat-form-field>
        <br>
        <mat-checkbox formControlName="on_sale" color="primary">On Sale</mat-checkbox>
        <br>
        <mat-form-field>
            <input matInput placeholder="Stock" formControlName="stock_quantity">
        </mat-form-field>
        <br>

        <button mat-raised-button color="primary" type="submit" [disabled]="woocommerceService.editProductForm.invalid">
            <mat-icon>send</mat-icon>&nbsp; Submit Changes
        </button>
    </form>
</mat-dialog-content>