import { WoocommerceService } from '../../../services/woocommerce.service';
import { Component, OnInit } from '@angular/core';
import { WoocommerceOrder } from 'src/app/models/woocommerceOrder';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-woocommerce.order-details',
  templateUrl: './woocommerce-order-details.component.html',
  styleUrls: ['./woocommerce-order-details.component.scss']
})
export class WoocommerceOrderDetailsComponent implements OnInit {
  
  order!: WoocommerceOrder;
  id!: any;

  constructor(private route: ActivatedRoute, public woocommerceService: WoocommerceService) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get("id")
    this.woocommerceService.getOrder(this.id).subscribe(
      response => {
        console.log(response);
        this.order = response;
      }
    )
  }
}