<h2>Initiate Call to: {{target}}</h2>
<p>{{sipUsers$}}</p>
<form [formGroup]="initCallForm" (ngSubmit)="submitHandler()">
    <input type = hidden formControlName="target">
            <select  class="selectpicker dropdown mt-4 ml-3" id="type" data-live-search="true" formControlName="sipUser">
                <option *ngFor="let u of sipUsers$ | async" [value]="u.sipUserId">{{u.firstName}}</option>
             </select>
    <br><br>
    <button mat-raised-button color="primary" type="submit">
        <mat-icon>send</mat-icon>&nbsp; Start Call
    </button>
</form>