import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor() { }

  checkEcommerceLicense(): boolean{
    if (localStorage.getItem('ECommerce') == "true") {
      return true;
    } else {
      return false
    }
  }
  checkPhoneLicense(): boolean {
    if (localStorage.getItem('Phone') == "true") {
      return true;
    } else {
      return false
    }
  }
  checkAdmin(): boolean {
    if (localStorage.getItem('Admin') == "true") {
      return true;
    } else {
      return false
    }
  }

}
