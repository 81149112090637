import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { htmlCostEstimate } from '../models/htmlCostEstimate.model';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class CostEstimateService {

  constructor(public httpClient: HttpClient, private fb: FormBuilder, public loginService: LoginService, private router: Router) { }

  newCustomerCostEstimateForm: FormGroup = new FormGroup({
    FirstName: new FormControl('', Validators.required),
    LastName: new FormControl('', Validators.required),
    Street: new FormControl('', Validators.required),
    StreetNumber: new FormControl('', Validators.required),
    PostalCode: new FormControl('', Validators.required),
    City: new FormControl('', Validators.required),
    Country: new FormControl('Deutshcland', Validators.required),
    Email: new FormControl('', Validators.required),
    TelephoneNumber: new FormControl('', Validators.required),
  });

  getCostEstimateHtml(id: number): Observable<htmlCostEstimate> {
    return this.httpClient.get<htmlCostEstimate>(this.loginService.getBackendUrl() + '/CostEstimate/html/' + id, { headers: this.loginService.getAuthHeader() });
  }
  getByCustomer(customer: number) {
    console.warn(customer)
    return this.httpClient.get<any>(this.loginService.getBackendUrl() + '/CostEstimate/byCustomer/' + customer, { headers: this.loginService.getAuthHeader() });
  }

  addNewCustomerCostEstimate(formData: any) {
    console.log(formData)
    this.httpClient.post(this.loginService.getBackendUrl() + '/CostEstimate/addNewCustomerEstimate/', formData, { headers: this.loginService.getAuthHeader() }).subscribe(
      (result) => {
        console.log("result", result)
        this.router.navigate(['/generate-cost-estimate/' + result.toString()]);
      }
    );
  }
  addExistingCustomerCostEstimate(formData: any) {
    console.log(formData)
    this.httpClient.post(this.loginService.getBackendUrl() + '/CostEstimate/addExistingCustomerEstimate/', formData, { headers: this.loginService.getAuthHeader() }).subscribe(
      (result) => {
        console.log("result", result)
        this.router.navigate(['/generate-cost-estimate/' + result.toString()]);
      }
    );
  }
  sendEstimateEmail(id: number) {
    console.log(id)
    this.httpClient.get(this.loginService.getBackendUrl() + '/CostEstimate/email/' + id, { headers: this.loginService.getAuthHeader() }).subscribe(
      (result) => {
        console.log("result", result)
      }
    );
  }
}
