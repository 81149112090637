<h2>Create new Cost Estimate</h2>
<form [formGroup]="newCustomerCostEstimateForm" [hidden]="success" (ngSubmit)="submitHandler()">
    <br>
    <h3>Customer Data:</h3>
    <mat-form-field>
        <input matInput placeholder="First Name" formControlName="firstName">
    </mat-form-field>
    <br>
    <mat-form-field>
        <input matInput placeholder="Last Name" formControlName="lastName">
    </mat-form-field>
    <br>
    <mat-form-field>
        <input matInput placeholder="Additional Info" formControlName="additionalInfo">
    </mat-form-field>
    <br>
    <mat-form-field>
        <input matInput placeholder="Street" formControlName="street">
    </mat-form-field>
    <br>
    <mat-form-field>
        <input matInput placeholder="Street Number" formControlName="streetNumber">
    </mat-form-field>
    <br>
    <mat-form-field>
        <input matInput placeholder="Postal Code" formControlName="postalCode">
    </mat-form-field>
    <br>
    <mat-form-field>
        <input matInput placeholder="City" formControlName="city">
    </mat-form-field>
    <br>
    <mat-form-field>
        <input matInput placeholder="Country" formControlName="country">
    </mat-form-field>
    <br>
    <mat-form-field>
        <input matInput placeholder="Email" formControlName="email">
    </mat-form-field>
    <br>
    <mat-form-field>
        <input matInput placeholder="Telephone Number" formControlName="telephoneNumber">
    </mat-form-field>
    <br>
    <h3>Items:</h3>
    <div formArrayName="items">
        <div *ngFor="let phone of itemForms.controls; let i=index" [formGroupName]="i">
            <select  class="selectpicker dropdown mt-4 ml-3" id="type" data-live-search="true" formControlName="id">
                <option>choose article...</option>
                <option *ngFor="let product of products$ | async" [value]="product.id">{{product.description}}</option>
             </select>
            <mat-form-field>
                <input matInput placeholder="Amount" formControlName="amount">
            </mat-form-field>
            <button mat-raised-button color="warn" (click)="deleteItem(i)">
                <mat-icon>remove</mat-icon>&nbsp; Delete
            </button>

        </div>
    </div>

    <button mat-raised-button type="button" (click)="addItem()">
        <mat-icon>add</mat-icon>&nbsp; Add Item
    </button>
    <br><br>
    <button mat-raised-button color="primary" type="submit" [disabled]="newCustomerCostEstimateForm.invalid">
        <mat-icon>send</mat-icon>&nbsp; Create Estimate
    </button>
</form>
<div *ngIf="success" class="notification is-success">
    Yay! We received your submission - please wait to be redirected
</div>