<mat-grid-list cols="2" rowHeight="450">
    <mat-grid-tile *ngIf="authService.checkPhoneLicense()">
        <!-- phone calls -->
        <mat-card class="phone-card">
            <mat-card-header>
                <div mat-card-avatar>
                    <mat-icon>phone</mat-icon>
                </div>
                <mat-card-title>Recent Calls
                </mat-card-title>
                <mat-card-subtitle>Calls Received today</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <table mat-table [dataSource]="phoneService.calls" class="mat-elevation-z8">

                    <!--- Note that these columns can be defined in any order.
                      The actual rendered columns are set as a property on the row definition" -->

                    <!-- Date Column -->
                    <ng-container matColumnDef="date">
                        <th mat-header-cell *matHeaderCellDef> Date </th>
                        <td mat-cell *matCellDef="let call">{{dateService.formatDate(call.date)}}&nbsp; &nbsp; </td>
                    </ng-container>

                    <!-- Date Column -->
                    <ng-container matColumnDef="number">
                        <th mat-header-cell *matHeaderCellDef> Caller </th>
                        <td mat-cell *matCellDef="let call">
                            <a *ngIf="call.customerID == 0">{{call.phoneNumber}}</a>
                            <a *ngIf="call.customerID != 0"
                                href="customer-details/{{call.customerID}}">{{call.customerName}}</a>
                            &nbsp;&nbsp;&nbsp;
                        </td>
                    </ng-container>

                    <!-- action Column -->
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef> Voicemail </th>
                        <td mat-cell *matCellDef="let call">
                            <a *ngIf="call.file" href="{{call.file}}" mat-raised-button color="primary">
                                &nbsp; <mat-icon>voicemail</mat-icon>&nbsp;Voicemail
                            </a>
                            <button *ngIf="call.status=='missed' && !call.file" mat-raised-button
                                (click)="makeCall(call.phoneNumber)">
                                <mat-icon>phone</mat-icon>&nbsp; Call back
                            </button>
                            <p *ngIf="call.status=='accepted'">
                                &nbsp; ✅
                            </p>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedPhoneColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedPhoneColumns;"></tr>
                </table>
                <br>
                <mat-icon matTooltip="For this to work you need to set your placetel API key in settings">info
                </mat-icon>
            </mat-card-content>
        </mat-card>
    </mat-grid-tile>
    <mat-grid-tile>
        <!-- calendar -->
        <mat-card class="calendar-card">
            <mat-card-header>
                <div mat-card-avatar>
                    <mat-icon>calendar_today</mat-icon>
                </div>
                <mat-card-title>Calendar</mat-card-title>
                <mat-card-subtitle>upcoming appointments</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <iframe
                    src="https://calendar.google.com/calendar/embed?height=300&amp;wkst=2&amp;bgcolor=%23ffffff&amp;ctz=Europe%2FBerlin&amp;src=NGE0cTY1bXU0aTVrOXAwbGdhMHB0YnFqODRAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;color=%2333B679&amp;showTz=0&amp;showCalendars=0&amp;showTabs=0&amp;showNav=0&amp;showTitle=1&amp;mode=AGENDA&amp;title=Termine&amp;showPrint=0&amp;showDate=1"
                    style="border:solid 1px #777" width="400" height="300" frameborder="0" scrolling="no"></iframe>
            </mat-card-content>
        </mat-card>
    </mat-grid-tile>
    <mat-grid-tile>
        <!-- recent contracts -->
        <mat-card class="contracts-card">
            <mat-card-header>
                <div mat-card-avatar>
                    <mat-icon>checklist</mat-icon>
                </div>
                <mat-card-title>Recent Contracts
                </mat-card-title>
                <mat-card-subtitle>Oldest contracts that needs to be finished</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <table mat-table [dataSource]="contracts" class="mat-elevation-z8">

                    <!--- Note that these columns can be defined in any order.
                      The actual rendered columns are set as a property on the row definition" -->

                    <!-- device Column -->
                    <ng-container matColumnDef="device">
                        <th mat-header-cell *matHeaderCellDef> Device </th>
                        <td mat-cell *matCellDef="let contract"> {{contract.device.manufacturer}}
                            {{contract.device.model}}&nbsp; </td>
                    </ng-container>

                    <!-- defect Column -->
                    <ng-container matColumnDef="defect">
                        <th mat-header-cell *matHeaderCellDef> Defect </th>
                        <td mat-cell *matCellDef="let contract"> {{contract.device.defect}}&nbsp; </td>
                    </ng-container>

                    <!-- status Column -->
                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef> Status </th>
                        <td mat-cell *matCellDef="let contract"> {{contract.status}}&nbsp; </td>
                    </ng-container>

                    <!-- action Column -->
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef> View Details </th>
                        <td mat-cell *matCellDef="let contract">
                            <a href="/generate-contract-receipt/{{contract.id}}" mat-raised-button color="primary">
                                &nbsp; <mat-icon>loupe</mat-icon>&nbsp;Details
                            </a>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedContractColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedContractColumns;"></tr>
                </table>
                <br>
            </mat-card-content>
        </mat-card>
    </mat-grid-tile>
    <mat-grid-tile>
        <!-- last Invoices -->
        <mat-card class="contracts-card">
            <mat-card-header>
                <div mat-card-avatar>
                    <mat-icon>checklist</mat-icon>
                </div>
                <mat-card-title>Latest Invoices
                </mat-card-title>
                <mat-card-subtitle>Last 5 invoices created</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <table mat-table [dataSource]="latestInvoices" class="mat-elevation-z8">

                    <!--- Note that these columns can be defined in any order.
                      The actual rendered columns are set as a property on the row definition" -->

                    <!-- date Column -->
                    <ng-container matColumnDef="date">
                        <th mat-header-cell *matHeaderCellDef> Date </th>
                        <td mat-cell *matCellDef="let invoice">
                            {{dateService.formatDate(invoice.invoice.invoiceDate)}}&nbsp;&nbsp; </td>
                    </ng-container>

                    <!-- customer Column -->
                    <ng-container matColumnDef="customer">
                        <th mat-header-cell *matHeaderCellDef> Customer </th>
                        <td mat-cell *matCellDef="let invoice">
                            <a href="/customer-details/{{invoice.customer.id}}">
                                {{invoice.customer.firstName}} {{invoice.customer.lastName}}
                            </a>
                            &nbsp;&nbsp;
                        </td>
                    </ng-container>

                    <!-- action Column -->
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef> Details </th>
                        <td mat-cell *matCellDef="let invoice">
                            <a href="/generate-invoice/{{invoice.invoice.id}}" mat-raised-button color="primary">
                                &nbsp; <mat-icon>visibility</mat-icon>&nbsp;View Invoice
                            </a>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedInvoicesColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedInvoicesColumns;"></tr>
                </table>
                <br>
            </mat-card-content>
        </mat-card>
    </mat-grid-tile>
</mat-grid-list>