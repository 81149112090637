import { LoginService } from './login.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FullPayroll } from '../models/fullPayroll.model';
import { Payroll } from '../models/payroll.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PayrollService {

  payrolls: Payroll[] = [];

  constructor(public httpClient: HttpClient, public loginService: LoginService) { }

  getPayrolls(employeeId: number) {
    this.httpClient.get<any>(this.loginService.getBackendUrl() + '/payroll/' + employeeId, { headers: this.loginService.getAuthHeader() }).subscribe(
      response => {
        console.log(response);
        this.payrolls = response;
      }
    );
  }

  getPayroll(id: number) {
    return this.httpClient.get<FullPayroll>(this.loginService.getBackendUrl() + '/payroll/getById/' + id, { headers: this.loginService.getAuthHeader() });
  }
}
