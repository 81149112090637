import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})

export class FeedbackService {

  constructor(public httpClient: HttpClient, public loginService: LoginService) { }

  feedbackForm: FormGroup = new FormGroup({
    senderEmail: new FormControl('', Validators.email),
    feedbackType: new FormControl(''),
    message: new FormControl('', Validators.required),
  });

  initializeFeedbackForm() {
    this.feedbackForm.setValue({
      senderEmail: '',
      feedbackType: '',
      message: ''
    });
  }

  sendFeedback(formData: any) {
    console.log(formData)
    this.httpClient.post(this.loginService.getBackendUrl() + '/feedback/', formData, { headers: this.loginService.getAuthHeader() }).subscribe(
      (result) => {
        console.log("result", result)
      }
    );
  }
}
