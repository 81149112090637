<mat-toolbar>
    <span>Send Feedback&nbsp;</span>
    <span class="fill-remaining-space"></span>
    <button class="btn-dialog-close" mat-stroked-button (click)="onClose()" tabIndex="-1">
        <mat-icon>clear</mat-icon>
    </button>
</mat-toolbar>
<mat-dialog-content>
    <form [formGroup]="feedbackService.feedbackForm" (ngSubmit)="onSubmit()">
        <br>
        <mat-form-field>
            <input matInput placeholder="Your Email" formControlName="senderEmail">
        </mat-form-field>
        <br>
        <mat-form-field appearance="fill">
            <mat-label>Select your Feedback Type</mat-label>
            <select formControlName="feedbackType" matNativeControl required>
              <option value="Bug Report">Bug Report</option>
              <option value="Feature Request">Feature Request</option>
              <option value="General Feedback">General Feedback</option>
            </select>
          </mat-form-field>
        <br>
        <mat-form-field>
            <input matInput placeholder="Message" formControlName="message">
        </mat-form-field>
        <br>
        <br>
        <button mat-raised-button color="primary" type="submit" [disabled]="feedbackService.feedbackForm.invalid">
            <mat-icon>send</mat-icon>&nbsp; Submit Feedback
        </button>
    </form>
</mat-dialog-content>