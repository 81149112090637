<h1> Step 1: Upload Invoice</h1>
<br>
<input #file type="file" multiple (change)="upload(file.files)">
<br><br>
<br>

<div *ngIf="incomingInvoiceService.ocr !== undefined">
    <h3>Step 2: Check OCR Predictions:</h3><br>
    number: {{incomingInvoiceService.ocr.invoiceNr}}<br>
    date: {{incomingInvoiceService.ocr.date}}<br>
    amount: {{incomingInvoiceService.ocr.amount}}<br>
    tax: {{incomingInvoiceService.ocr.tax}}<br>
    annotation: {{incomingInvoiceService.ocr.annotation}}<br>
    file: {{incomingInvoiceService.ocr.filename}}<br>

    <button mat-raised-button (click)="openConfirmForm()">
        <mat-icon>send</mat-icon>&nbsp; Confirm data
    </button>
</div>