import { SearchService } from './../services/search.service';
import { EditAccountComponent } from './../pages/login/edit-account/edit-account.component';
import { LoginService } from './../services/login.service';
import { Component, ViewChild } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { faFileContract, faFileInvoiceDollar, faUser, faShoppingCart, faHome } from '@fortawesome/free-solid-svg-icons';
import { LoginComponent } from '../pages/login/login.component';
import { MatDialog } from '@angular/material/dialog';
import { Account } from '../models/account.model';
import { SearchResult } from '../models/searchResult.model';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { FeedbackComponent } from '../pages/feedback/feedback.component';
import { FeedbackService } from '../services/feedback.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent {

  @ViewChild('searchInput') searchInput: any // accessing the searchbar input element
  isDarkTheme: boolean = false;
  isLoggedIn: boolean = false;
  searchResults!: SearchResult[];

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(private breakpointObserver: BreakpointObserver, public LoginService: LoginService, private dialog: MatDialog, public authService: AuthService, public searchService: SearchService, private router: Router, public feedbackService: FeedbackService) { }
  faContract = faFileContract;
  faInvoice = faFileInvoiceDollar;
  faProduct = faShoppingCart;
  faCustomer = faUser;
  faHome = faHome;

  ngOnInit(): void {
    //check dark mode
    this.isDarkTheme = localStorage.getItem('theme') === "Dark" ? true : false;
    //check if user token expired
    this.checkAutoLogout();
  }

  checkAutoLogout(): void {
    if (localStorage.getItem('username') != null) {
      let expires = new Date((localStorage.getItem('token_expires') || '').toString());
      let now = new Date();
      if (now.getTime() < expires.getTime()) {
        this.isLoggedIn = true;
      } else {
        this.isLoggedIn = false;
        localStorage.removeItem('username')
        localStorage.removeItem('token')
        localStorage.removeItem('token_expires')
        this.openLoginForm()
      }
    }
    else {
      this.isLoggedIn = false;
      this.openLoginForm()
    }
  }

  getLogin(): String {
    if (localStorage.getItem('username') != null) {
      return "Hello, " + localStorage.getItem('username');
    } else {
      return "Please login to view contents"
    }
    //this.isLoggedIn = localStorage.getItem('user') != null ? true : false;
  }
  storeThemeSelection() {
    localStorage.setItem('theme', this.isDarkTheme ? "Dark" : "Light")
  }

  openLoginForm() {
    this.LoginService.initializeFormGroup();
    this.dialog.open(LoginComponent);
  }
  openAccountSettings() {
    this.LoginService.initializeChangePasswordForm();
    this.dialog.open(EditAccountComponent);
  }
  logout() {
    this.LoginService.logout();
  }
  onFeedback() {
    this.feedbackService.initializeFeedbackForm();
    this.dialog.open(FeedbackComponent);
  }
  superSearch(query: any) {
    console.log(query.target.value)
    if (query.target.value.length >= 2) {  //only search if at least 2 chars are typed to reduce response size - this is also limited in the backend
      this.searchService.superSearch(query.target.value).subscribe(
        response => {
          console.log(response);
          this.searchResults = response;
        }
      )
    }
  }
  searchClick(link: string) {
    console.log("navigating to: " + link)
    this.searchResults = []
    this.router.navigate(['/' + link])
    this.searchInput.nativeElement.value = '';
  }
  searchBarNotEmpty() {
    if (this.searchInput == null) {
      return false
    }
    if (this.searchInput.nativeElement.value == '') {
      return false
    }
    else {
      return true
    }
  }
}