import { LoginService } from './../../../services/login.service';
import { Component, OnInit } from '@angular/core';
import { AddUserComponent } from '../add-user/add-user.component';
import { MatDialog } from '@angular/material/dialog';
import { User } from 'src/app/models/user.model';

@Component({
  selector: 'app-user-manager',
  templateUrl: './user-manager.component.html',
  styleUrls: ['./user-manager.component.scss']
})
export class UserManagerComponent implements OnInit {

  constructor(public loginService: LoginService, private dialog: MatDialog) { }

  displayedColumns: string[] = ['name', 'email', 'action'];

  ngOnInit(): void {
    this.loginService.getUsers();
  }
  openAddNewUser() {
    this.loginService.initializeAddNewUserForm();
    this.dialog.open(AddUserComponent);
  }
  removeUser(user: User){
    if(confirm("Are you sure to remove user: "+user.Name+"? This can not be reverted!!")){
      this.loginService.removeUser(user);
      window.location.reload()
    }
  }
  resetPassword(user: User){
    if(confirm("Are you sure to reset the Password for user: "+user.Name+"? A new Password will be send via email")){
      this.loginService.resetPassword(user);
    }
  }

}
