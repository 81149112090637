import { ocrResponse } from './../models/ocrResponse.model';
import { DateService } from 'src/app/services/date.service';
import { LoginService } from './login.service';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IncomingInvoice } from '../models/incomingInvoice.model';
import { Product } from '../models/product.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IncomingInvoiceService {

  ocr!: ocrResponse;

  incomingInvoices: IncomingInvoice[] = [];

  constructor(public httpClient: HttpClient, public loginService: LoginService) { }

  confirmInvoiceForm: FormGroup = new FormGroup({
    filename: new FormControl(),
    invoiceNr: new FormControl('', Validators.required),
    date: new FormControl(),
    amount: new FormControl('', Validators.required),
    tax: new FormControl('', Validators.required),
    annotation: new FormControl('', Validators.required)
  });

  initializeConfirmInvoiceForm() {
    this.confirmInvoiceForm.setValue({
      filename: '',
      invoiceNr: '',
      date: '',
      amount: '',
      tax: '',
      annotation: ''
    });
  }

  populateConfirmForm(ocr: ocrResponse) {
    this.confirmInvoiceForm.patchValue({
      filename: ocr.filename,
      invoiceNr: ocr.invoiceNr,
      date: ocr.date,
      amount: ocr.amount,
      tax: ocr.tax,
      annotation: ocr.annotation
    });
  }

  getIncomingInvoices() {
    this.httpClient.get<any>(this.loginService.getBackendUrl() + '/ExpenseReport', { headers: this.loginService.getAuthHeader() }).subscribe(
      response => {
        console.log(response);
        this.incomingInvoices = response;
      }
    );
  }

  uploadInvoice(formData: any) {
    this.httpClient.post<ocrResponse>(this.loginService.getBackendUrl() + '/ExpenseReport/upload',formData).subscribe(
      response => {
        console.log(response);
        this.ocr = response;
      }
    );
  }

  confirmInvoice(formData: any) {
    this.httpClient.post<any>(this.loginService.getBackendUrl() + '/ExpenseReport/confirm',formData, { headers: this.loginService.getAuthHeader() }).subscribe(
      response => {
        console.log("confirm response: "+response);
      }
    );
  }

  downloadExpensesByDate(start: string, end: string) {
    console.log("requesting expense reports from start: "+start+" to end: "+end)
    return this.httpClient
          .get(this.loginService.getBackendUrl() + '/ExpenseReport/zip/range?start='+start+'&end='+end, {headers: this.loginService.getAuthHeader(), responseType: 'arraybuffer'})
  }
}
