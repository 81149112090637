import { CostEstimateService } from 'src/app/services/cost-estimate.service';
import { ContractService } from './../../../services/contract.service';
import { Customer } from './../../../models/customer.model';
import { CustomerService } from './../../../services/customer.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faEye, faPencilAlt, faPlus } from '@fortawesome/free-solid-svg-icons';
import { DatePipe } from '@angular/common';
import { UpdateCustomerComponent } from '../update-customer/update-customer.component';
import { MatDialog } from '@angular/material/dialog';
import { Address } from 'src/app/models/address.model';
import { UpdateAddressComponent } from '../update-address/update-address.component';
import { ExistingCustomerContractComponent } from '../../contract/existing-customer-contract/existing-customer-contract.component';
import { CostEstimate } from 'src/app/models/costEstimate.model';
@Component({
  selector: 'app-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.scss']
})
export class CustomerDetailsComponent implements OnInit {
  estimates!: CostEstimate[];
  customer!: Customer;
  id!: any;
  displayedColumns: string[] = ['date', 'action'];
  displayedEstimateColumns: string[] = ['date', 'action'];
  displayedContractColumns: string[] = ['date', 'status', 'action'];

  //icons
  faUpdate = faPencilAlt;
  faView = faEye;
  faPlus = faPlus;

  constructor(public costEstimateService: CostEstimateService, public CustomerService: CustomerService, private route: ActivatedRoute, private dialog: MatDialog, private contractService: ContractService) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get("id")
    console.log(this.id);
    this.costEstimateService.getByCustomer(this.id).subscribe(
      response => {
        console.log(response);
        this.estimates = response;
      }
    );
    this.CustomerService.getCustomer(this.id).subscribe(
      response => {
        console.log(response);
        this.customer = response;
      }
    );
  }

  getDate(d: string): any {
    const datepipe: DatePipe = new DatePipe("de-DE")
    let formattedDate = datepipe.transform(d, 'dd.MM.YYYY');
    console.log(formattedDate)
    return formattedDate
  }

  onEdit(id: number, customer: Customer) {
    this.CustomerService.populateUpdateForm(customer);
    console.log(customer)
    this.dialog.open(UpdateCustomerComponent);
  }
  onEditAddress(id: number, address: Address) {
    this.CustomerService.populateAddressForm(address);
    console.log(address)
    this.dialog.open(UpdateAddressComponent);
  }
  onCreateContract(c: number) {
    this.contractService.initializeExistingCustomerForm(c);
    this.dialog.open(ExistingCustomerContractComponent);
  }

}
