<br>
<button mat-raised-button (click)="onAddEmployee()">
    <mat-icon>add</mat-icon>&nbsp; Add Employee
</button>
<br>
<br>
<h2>Employees</h2>
<i class="fas fa-file-contract"></i>
<table mat-table [dataSource]="employeeService.employees" class="mat-elevation-z8">

    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

    <!-- Name Column -->
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let employee"> {{employee.firstName}} {{employee.lastName}} &nbsp;&nbsp;</td>
    </ng-container>

    <!-- Email Column -->
    <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef> Email </th>
        <td mat-cell *matCellDef="let employee"> <a href="mailto:{{employee.email}}">{{employee.email}}</a> &nbsp;</td>
    </ng-container>

    <!-- Telephone Column -->
    <ng-container matColumnDef="telephone">
        <th mat-header-cell *matHeaderCellDef> Telephone </th>
        <td mat-cell *matCellDef="let employee"> {{employee.telephone}} &nbsp;&nbsp;</td>
    </ng-container>

    <!-- Details Column -->
    <ng-container matColumnDef="details">
        <th mat-header-cell *matHeaderCellDef> Details </th>
        <td mat-cell *matCellDef="let employee">
            <a href="employee-details/{{employee.id}}" mat-raised-button color="primary">&nbsp; <mat-icon>
                    manage_accounts</mat-icon> Details</a>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>