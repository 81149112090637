import { PhoneService } from './../../../services/phone.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-initiate-call',
  templateUrl: './initiate-call.component.html',
  styleUrls: ['./initiate-call.component.scss']
})
export class InitiateCallComponent implements OnInit {

  initCallForm!: FormGroup;
  sipUsers$: any;
  target!: any;

  constructor(public phoneService: PhoneService, private fb: FormBuilder, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.sipUsers$ = this.getSipUsers();
    this.target = this.route.snapshot.paramMap.get("target")
    this.initCallForm = this.fb.group({
      target: this.target,
      user: ""
    })
    console.log(this.sipUsers$[0])
  }

  async submitHandler() {
    const formData = this.initCallForm.value;
    console.warn(formData);

    try {
      this.phoneService.initiateCall(formData)
    } catch (err) {
      console.error(err)
    }

  }
  getSipUsers(): any{  
    return this.phoneService.getSipUsers()
  }

}
