<mat-toolbar>
    <span>Login</span>
</mat-toolbar>
<mat-dialog-content>
    <form [formGroup]="LoginService.loginForm" (ngSubmit)="onSubmit()">
        <br>
        <mat-form-field>
            <input matInput placeholder="Backend URL" formControlName="backend">
        </mat-form-field>
        <br>
        <mat-form-field>
            <input matInput placeholder="Username" formControlName="name">
        </mat-form-field>
        <br>
        <mat-form-field>
            <input matInput type="password" placeholder="Password" formControlName="password">
        </mat-form-field>
        <br>
        <button mat-raised-button color="primary" type="submit" [disabled]="LoginService.loginForm.invalid">
            <mat-icon>send</mat-icon>&nbsp; Login
        </button>
    </form>

</mat-dialog-content>