import { WoocommerceUpdateProductComponent } from './../woocommerce-update-product/woocommerce-update-product.component';
import { WoocommerceProduct } from './../../../models/woocommerceProduct.model';
import { Component, OnInit } from '@angular/core';
import { WoocommerceService } from 'src/app/services/woocommerce.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-woocommerce-products',
  templateUrl: './woocommerce-products.component.html',
  styleUrls: ['./woocommerce-products.component.scss']
})
export class WoocommerceProductsComponent implements OnInit {

  displayedColumns: string[] = ['id', 'slug', 'price', 'stock', 'store', 'action'];

  constructor(public woocommerceService: WoocommerceService, private dialog: MatDialog,) { }

  ngOnInit(): void {
    console.log("getting products")
    this.woocommerceService.getProducts();
  }
  onEditContract(product: WoocommerceProduct) {
    this.woocommerceService.populateEditProductForm(product);
    this.dialog.open(WoocommerceUpdateProductComponent);
  }

}
