<div *ngIf="customer !== undefined">
    <mat-tab-group>
        <mat-tab label="Customer Information">
            <mat-card class="customer-card">
                <mat-card-header>
                    <div mat-card-avatar class="example-header-image"></div>
                    <mat-card-title>{{customer.firstName}} {{customer.lastName}}</mat-card-title>
                    <mat-card-subtitle>ID: {{customer.id}}</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                    <h2>Address:</h2>
                    <p>
                        {{customer.address.street}} {{customer.address.streetNumber}}<br>
                        {{customer.address.additionalInfo}}<br>
                        {{customer.address.postalCode}} {{customer.address.city}}<br>
                        {{customer.address.country}}
                    </p>
                    <button mat-button (click)="onEditAddress(customer.address.id, customer.address)">
                        <mat-icon>edit</mat-icon>&nbsp; Edit Address
                    </button>
                    <br>
                    <br>
                    <h2>Contact information:</h2>
                    <p>
                        E-Mail: {{customer.email}}<br>
                        Tel.: {{customer.telephoneNumber}}<br>
                    </p>
                    <br>
                </mat-card-content>
                <mat-card-actions>
                    <button mat-button (click)="onEdit(customer.id, customer)">
                        <mat-icon>edit</mat-icon>&nbsp; Edit Customer
                    </button>
                    <a href="/existing-customer-invoice/{{customer.id}}" mat-button color="primary">
                        <mat-icon>add</mat-icon>&nbsp; Create Invoice
                    </a>
                    <a href="/existing-customer-cost-estimate/{{customer.id}}" mat-button color="primary">
                        <mat-icon>add</mat-icon>&nbsp; Create Cost Estimate
                    </a>
                </mat-card-actions>
            </mat-card>
        </mat-tab>
        <mat-tab label="Invoices">
            <mat-card class="invoices-card">
                <mat-card-header>
                    <mat-card-title>Invoices</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <table mat-table [dataSource]="customer.invoices" class="mat-elevation-z8">

                        <!--- Note that these columns can be defined in any order.
                          The actual rendered columns are set as a property on the row definition" -->

                        <!-- Date Column -->
                        <ng-container matColumnDef="date">
                            <th mat-header-cell *matHeaderCellDef> Date </th>
                            <td mat-cell *matCellDef="let invoice"> {{getDate(invoice.invoiceDate)}} </td>
                        </ng-container>

                        <!-- Action Column -->
                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef> Action </th>
                            <td mat-cell *matCellDef="let invoice">
                                <a href="/generate-invoice/{{invoice.id}}" mat-raised-button color="primary">
                                    <fa-icon [icon]="faView"></fa-icon>&nbsp; View Invoice
                                </a>&nbsp;
                                <a href="" mat-raised-button color="warn">
                                    <fa-icon [icon]="faUpdate"></fa-icon>&nbsp; Edit
                                </a>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </mat-card-content>
                <mat-card-actions>
                    <a href="/existing-customer-invoice/{{customer.id}}" mat-button>
                        <fa-icon [icon]="faPlus"></fa-icon>&nbsp; Create Invoice
                    </a>&nbsp;
                </mat-card-actions>
            </mat-card>
        </mat-tab>
        <mat-tab label="Contracts">
            <mat-card class="contracts-card">
                <mat-card-header>
                    <mat-card-title>Active Contracts</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <table mat-table [dataSource]="customer.contracts" class="mat-elevation-z8">

                        <!--- Note that these columns can be defined in any order.
                              The actual rendered columns are set as a property on the row definition" -->

                        <!-- Date Column -->
                        <ng-container matColumnDef="date">
                            <th mat-header-cell *matHeaderCellDef> Date </th>
                            <td mat-cell *matCellDef="let contract"> {{getDate(contract.date)}} </td>
                        </ng-container>

                        <!-- Status Column -->
                        <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef> Status </th>
                            <td mat-cell *matCellDef="let contract"> {{contract.status}} </td>
                        </ng-container>

                        <!-- Action Column -->
                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef> Action </th>
                            <td mat-cell *matCellDef="let contract">
                                <a href="/generate-contract-receipt/{{contract.id}}" mat-raised-button color="primary">
                                    <fa-icon [icon]="faView"></fa-icon>&nbsp; View Contract
                                </a>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedContractColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedContractColumns;"></tr>
                    </table>
                </mat-card-content>
                <mat-card-actions>
                    <button mat-button (click)="onCreateContract(customer.id)">
                        <mat-icon>add</mat-icon>&nbsp; Create Contract
                    </button>
                </mat-card-actions>
            </mat-card>
        </mat-tab>
        <mat-tab label="Cost Estimates">
            <mat-card class="costEstimates-card">
                <mat-card-header>
                    <mat-card-title>Cost Estimates</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <table mat-table [dataSource]="estimates" class="mat-elevation-z8">

                        <!--- Note that these columns can be defined in any order.
                          The actual rendered columns are set as a property on the row definition" -->

                        <!-- Date Column -->
                        <ng-container matColumnDef="date">
                            <th mat-header-cell *matHeaderCellDef> Date </th>
                            <td mat-cell *matCellDef="let estimate"> {{getDate(estimate.date)}} </td>
                        </ng-container>

                        <!-- Action Column -->
                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef> Action </th>
                            <td mat-cell *matCellDef="let estimate">
                                <a href="/generate-cost-estimate/{{estimate.id}}" mat-raised-button color="primary">
                                    <fa-icon [icon]="faView"></fa-icon>&nbsp; View Estimate
                                </a>&nbsp;
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedEstimateColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedEstimateColumns;"></tr>
                    </table>
                </mat-card-content>
                <mat-card-actions>
                    <a href="/existing-customer-cost-estimate/{{customer.id}}" mat-button>
                        <mat-icon>add</mat-icon>&nbsp; Create Estimate
                    </a>&nbsp;
                </mat-card-actions>
            </mat-card>
        </mat-tab>
    </mat-tab-group>
</div>
<div *ngIf="customer === undefined">
    customer is beeing loaded please wait... If the customer does not load try the following steps: 
    <ul>
        <li>Ensure the customer exists</li>
        <li>Reload this webpage</li>
        <li>Logut and login to your xedap instance</li>
        <li>If the two options above do not work wite a bug report and contact our customer supprot at <a href= "mailto:info@xedap.de">info@xedap.de</a></li>
    </ul>
</div>