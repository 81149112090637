<mat-toolbar>
    <span>Create Contract and Customer</span>
    <span class="fill-remaining-space"></span>
    <button class="btn-dialog-close" mat-stroked-button (click)="onClose()" tabIndex="-1">
        <mat-icon>clear</mat-icon>
    </button>
</mat-toolbar>
<mat-dialog-content>
    <form [formGroup]="employeeService.addEmployeeForm" (ngSubmit)="onAddEmployee()">
        <br>
        <mat-form-field>
            <input matInput placeholder="First Name" formControlName="firstName">
        </mat-form-field>
        <br>
        <mat-form-field>
            <input matInput placeholder="Last Name" formControlName="lastName">
        </mat-form-field>
        <br>
        <mat-form-field>
            <input matInput placeholder="Street" formControlName="street">
        </mat-form-field>
        <br>
        <mat-form-field>
            <input matInput placeholder="Street Number" formControlName="streetNumber">
        </mat-form-field>
        <br>
        <mat-form-field>
            <input matInput placeholder="Postal Code" formControlName="postalCode">
        </mat-form-field>
        <br>
        <mat-form-field>
            <input matInput placeholder="City" formControlName="city">
        </mat-form-field>
        <br>
        <mat-form-field>
            <input matInput placeholder="Email" formControlName="email">
        </mat-form-field>
        <br>
        <mat-form-field>
            <input matInput placeholder="Phone Number" formControlName="telephone">
        </mat-form-field>
        <br>
        <mat-form-field>
            <input matInput placeholder="Loan" formControlName="loan">
        </mat-form-field>
        <br>
        <mat-form-field>
            <input matInput placeholder="Bank Account Owner" formControlName="bankAccountOwner">
        </mat-form-field>
        <br>
        <mat-form-field>
            <input matInput placeholder="Bank Name" formControlName="bank">
        </mat-form-field>
        <br>
        <mat-form-field>
            <input matInput placeholder="IBAN" formControlName="iban">
        </mat-form-field>
        <br>
        <mat-form-field>
            <input matInput placeholder="BIC" formControlName="bic">
        </mat-form-field>
        <br>
        <mat-checkbox formControlName="privateInsurance" color="primary">Private insurance</mat-checkbox>
        <br>
        <mat-form-field>
            <input matInput placeholder="Tax Number" formControlName="taxNumber">
        </mat-form-field>
        <br>
        <mat-form-field>
            <input matInput placeholder="Pension insurance number" formControlName="pensionInsuranceNumber">
        </mat-form-field>
        <br>
        <mat-checkbox formControlName="fullPensionInsurance" color="primary">Full pension insutance</mat-checkbox>
        <br>

        <button mat-raised-button color="primary" type="submit" [disabled]="employeeService.addEmployeeForm.invalid">
            <mat-icon>send</mat-icon>&nbsp; Add new Employee
        </button>
    </form>
</mat-dialog-content>