import { PayrollService } from './../../../services/payroll.service';
import { EmployeeService } from './../../../services/employee.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Employee } from 'src/app/models/employee.model';
import { EditEmployeeComponent } from '../edit-employee/edit-employee.component';

@Component({
  selector: 'app-employee-details',
  templateUrl: './employee-details.component.html',
  styleUrls: ['./employee-details.component.scss']
})
export class EmployeeDetailsComponent implements OnInit {

  employee!: Employee;
  id!: any;
  payrollColumns: string[] = ['month', 'hours', 'sumNet', 'sumExpenditure', 'link'];

  constructor(public employeeService: EmployeeService, public payrollService: PayrollService, private route: ActivatedRoute, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get("id")
    this.employeeService.getEmployee(this.id).subscribe(
      response => {
        console.log(response);
        this.employee = response;
      }
    )
    this.payrollService.getPayrolls(this.id);
  }
  onEdit(employee: Employee) {
    this.employeeService.populateUpdateForm(employee);
    console.log(employee)
    this.dialog.open(EditEmployeeComponent);
  }

}
