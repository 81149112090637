import { AuthService } from './auth.service';
import { LoginService } from './login.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { NotificationService } from './notification.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(public httpClient: HttpClient, public loginService: LoginService, public notificationService: NotificationService, private router: Router) { }

  placetelForm: FormGroup = new FormGroup({
    key: new FormControl('', Validators.required)
  });

  woocommerceForm: FormGroup = new FormGroup({
    url: new FormControl('', Validators.required),
    clientKey: new FormControl('', Validators.required),
    clientSecret: new FormControl('', Validators.required)
  });

  logoForm: FormGroup = new FormGroup({
    logo: new FormControl('', Validators.required)
  });

  setPlacetelApiKey(formData: any) {
    console.log(formData)
    this.httpClient.post(this.loginService.getBackendUrl() + '/settings/setPlacetelApiKey', formData, { headers: this.loginService.getAuthHeader() }).subscribe(
      (result) => {
        console.log("result", result)
      }
    );
  }

  setWoocommerceApiKeys(formData: any) {
    console.log(formData)
    this.httpClient.post(this.loginService.getBackendUrl() + '/settings/setWoocommerceApiKeys', formData, { headers: this.loginService.getAuthHeader() }).subscribe(
      (result) => {
        console.log("result", result)
      }
    );
  }
  checkWoocommerceApiKey () {
    this.httpClient.get(this.loginService.getBackendUrl() + '/settings/checkWoocommerceApiKey', { headers: this.loginService.getAuthHeader() }).subscribe(
      (result) => {
        console.log("result", result)
        console.log(result.toString())
        if(result.toString() =="false"){
          if(confirm("You need to set the Woocommerce API Key in settings for this to work!")){
            this.router.navigate(['/settings/']);
          }
        }
      }
    );
  }

  backupDatabase() {
    console.log('creating database backup')
    this.httpClient.get(this.loginService.getBackendUrl() + '/backup/database', { headers: this.loginService.getAuthHeader() }).subscribe(
      (result) => {
        console.log("result", result)
        if(result == null){
          this.notificationService.success('Database backup saved successfully!');
        }
      }
    );
  }
  updateLogo(formData: any) {
    console.log(formData)
    //TODO
  }

  initializePlacatelForm() {
    this.placetelForm.setValue({
      key: '',
    });
  }
  initializeWoocommerceForm() {
    this.placetelForm.setValue({
      ulr: '',
      clientKey: '',
      clientSecret: ''
    });
  }
  initializeLogoForm() {
    this.logoForm.setValue({
      logo: '',
    });
  }
}
