import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from 'src/app/services/notification.service';
import { CustomerService } from './../../../services/customer.service';

@Component({
  selector: 'app-update-address',
  templateUrl: './update-address.component.html',
  styleUrls: ['./update-address.component.scss']
})

export class UpdateAddressComponent implements OnInit {

  constructor(
    public CustomerService: CustomerService, 
    public dialogRef: MatDialogRef<UpdateAddressComponent>,
    public notificationService: NotificationService
    ) { }

  ngOnInit(): void {
  }

  onClear() {
    this.CustomerService.updateAddressForm.reset();
    this.CustomerService.initializeFormGroup();
    this.notificationService.success('Address Update data submitted successfully');
  }

  onClose() {
    this.CustomerService.updateAddressForm.reset();
    this.CustomerService.initializeFormGroup();
    this.dialogRef.close();
  }

  onSubmit() {
    this.CustomerService.updateAddress(this.CustomerService.updateAddressForm.value);
    this.CustomerService.updateAddressForm.reset();
    this.CustomerService.initializeFormGroup();
    this.notificationService.success('Edited address data submitted successfully');
    this.onClose();
  }

}
