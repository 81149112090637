import { DateService } from './../../services/date.service';
import { EditContractComponent } from './edit-contract/edit-contract.component';
import { NewContractComponent } from './new-contract/new-contract.component';
import { ContractService } from './../../services/contract.service';
import { Component, OnInit } from '@angular/core';
import { Contract } from 'src/app/models/contract.model';
import { faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons';
import { faFilePdf, faCheckSquare } from '@fortawesome/free-regular-svg-icons';
import { MatDialog } from '@angular/material/dialog';




@Component({
  selector: 'app-contract',
  templateUrl: './contract.component.html',
  styleUrls: ['./contract.component.scss']
})
export class ContractComponent implements OnInit {

  constructor(public ContractService: ContractService, private dialog: MatDialog, public dateService: DateService) { }
  displayedColumns: string[] = ['date', 'device', 'defect', 'status', 'box', 'customer', 'action'];
  contracts!: Contract[];

  // icons
  faInvoice = faFileInvoiceDollar;
  faPDF = faFilePdf;
  faCheck = faCheckSquare;

  ngOnInit(): void {
    this.ContractService.getContracts().subscribe(
      response => {
        console.log(response);
        this.contracts = response;
      }
    );;
  }

  onCreate() {
    this.ContractService.initializeFormGroup();
    this.dialog.open(NewContractComponent);
  }

  onEditContract(id: number, status: string) {
    this.ContractService.populateEditContractForm(id, status);
    this.dialog.open(EditContractComponent);
  }

  loadContractPDF(id: number) {

  }
  finish(id: number) {
    console.log(id);
    this.ContractService.finishContract(id);
  }


}
