import { Component, OnInit } from '@angular/core';
import { ProductService } from './../../../services/product.service';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-product-form',
  templateUrl: './product-form.component.html',
  styleUrls: ['./product-form.component.scss']
})
export class ProductFormComponent implements OnInit {

  productForm!: FormGroup;
  netPrice!: number;

  // Form state
  loading = false;
  success = false;

  //icons
  faSubmit=faPaperPlane;

  constructor(public ProductService:ProductService, private fb:FormBuilder) { }

  ngOnInit(): void {
    this.productForm = this.fb.group({
      id: '',
      description: '',
      buyNet: '0',
      buyGross: '0',
      url: '',
      margin: '0',
      price: '',
      isPublic: true,
      stockIs: '1',
      stockTarget: 0
    })
  }
  async submitHandler() {
    this.loading = true;
    const formData = this.productForm.value;
    console.log(formData);

    try {
      this.ProductService.postProduct(formData)
      this.success = true;
    } catch(err) {
      console.error(err)
    }

    this.loading = false;
    
  }
}
