<div *ngIf="invoice !== undefined">
    <br>
    <button mat-raised-button printSectionId="print-section" ngxPrint>print</button>&nbsp;
    <a href="/generate-invoice/{{invoice.invoice.id}}" mat-stroked-button color="primary">
        <mat-icon>local_shipping</mat-icon>&nbsp; Remove shipping label
    </a>
    <html>
    <div id="print-section">

        <head>
            <meta charset="utf-8" />
            <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta2/dist/css/bootstrap.min.css" rel="stylesheet"
                integrity="sha384-BmbxuPwQa2lc/FVzBcNJ7UAyJxM6wuqIj61tLrc4wSX0szH/Ev+nYRRuWlolflfl"
                crossorigin="anonymous">
            <!-- A4 CSS -->
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/paper-css/0.3.0/paper.css">

            <title>Invoice {{invoice.invoice.id}}</title>
        </head>

        <!-- Set "A5", "A4" or "A3" for class name -->
        <!-- Set also "landscape" if you need -->

        <body class="A4">


            <!-- Each sheet element should have the class "sheet" -->
            <!-- "padding-**mm" is optional: you can set 10, 15, 20 or 25 -->
            <section class="sheet padding-15mm">

                <!-- Write HTML just like a web page -->
                <div class="col-12">
                    <div class="row">
                        <div class="col-md-6">
                            <img src="/assets/mobiloparts.png" width="300">
                        </div>
                        <div class="col-md-6 text-end">
                            <p class="text-muted">
                                <small>
                                    {{invoice.config.street}}<br>
                                    {{invoice.config.postalCode}} {{invoice.config.city}}<br>
                                    Tel.: {{invoice.config.telephone}}<br>
                                    E-Mail: {{invoice.config.email}}<br>
                                    Homepage: www.mobiloparts.de<br>
                                    SteuerNr.: {{invoice.config.taxNumber}}<br>
                                    Finanzamt: {{invoice.config.taxOffice}}<br>
                                    Kontoinhaber: {{invoice.config.bankAccountOwner}}<br>
                                    IBAN: {{invoice.config.iban}}
                                </small>
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <p>
                                <small>
                                    {{invoice.customer.firstName}} {{invoice.customer.lastName}}<br>
                                    {{invoice.customer.address.street}} {{invoice.customer.address.streetNumber}}<br>
                                    {{invoice.customer.address.postalCode}} {{invoice.customer.address.city}}<br>
                                    {{invoice.customer.address.country}}<br>
                                </small>
                            </p>
                        </div>
                        <div class="col-md-6 text-end">
                            <p>
                                <small>
                                    <br>
                                    Rechnungsdatum: {{getDate(invoice.invoice.invoiceDate)}} <br>
                                    Rechnungs-Nr.: {{invoice.invoice.id}} <br> </small>
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col">Anzahl</th>
                                    <th scope="col">Art. Nr.</th>
                                    <th scope="col">Beschreibung</th>
                                    <th scope="col" class="text-end">Preis (Netto)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of invoice.invoice.invoiceItems">
                                    <td>{{item.amount}}</td>
                                    <td>{{item.productID}}</td>
                                    <td>{{item.productDescription}}</td>
                                    <td class="text-end">{{item.price}}€</td>
                                </tr>
                                <tr>
                                    <td colspan="4"></td>
                                </tr>
                                <tr>
                                    <td colspan="3">Gesamtbetrag Netto</td>
                                    <td class="text-end">{{invoice.sumNet}}€</td>
                                </tr>
                                <tr>
                                    <td colspan="3">Umsatzsteuer (19%)</td>
                                    <td class="text-end">{{invoice.tax}}€</td>
                                </tr>
                                <tr>
                                    <td colspan="3"><b>Gesamtbetrag</b></td>
                                    <td class="text-end"><b>{{invoice.sumGross}}€</b></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="row">
                        <div class="col">
                            <p><small>{{invoice.paymentConditions}}</small></p>
                        </div>
                    </div>
                    <div style="position: absolute;
                bottom: 3cm;
                left: 1cm;
                width: 8cm;
                height: 5cm;
                border: 1px solid #000000;">
                        <div class="col">
                            {{invoice.customer.firstName}} {{invoice.customer.lastName}}<br>
                            <small>{{invoice.customer.address.additionalInfo}}</small><br>
                            {{invoice.customer.address.street}} {{invoice.customer.address.streetNumber}}<br>
                            {{invoice.customer.address.postalCode}} {{invoice.customer.address.city}}<br>
                            {{invoice.customer.address.country}}<br>
                        </div>
                    </div>
                </div>

            </section>
            <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta2/dist/js/bootstrap.bundle.min.js"
                integrity="sha384-b5kHyXgcpbZJO/tY9Ul7kGkf1S0CWuKcCD38l8YkeH8z8QjE0GmW1gYU5S9FOnJ0"
                crossorigin="anonymous"></script>

        </body>

    </div>

    </html>
</div>
<div *ngIf="invoice === undefined">
    invoice is beeing loaded please wait... If the invoice does not load try the following steps:
    <ul>
        <li>Ensure the invoice exists</li>
        <li>Reload this webpage</li>
        <li>Logut and login to your xedap instance</li>
        <li>If the two options above do not work wite a bug report and contact our customer supprot at <a
                href="mailto:info@xedap.de">info@xedap.de</a></li>
    </ul>
</div>