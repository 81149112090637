import { AddEmployeeComponent } from './add-employee/add-employee.component';
import { EmployeeService } from './../../services/employee.service';
import { Component, OnInit } from '@angular/core';
import { DateService } from 'src/app/services/date.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.scss']
})
export class EmployeeComponent implements OnInit {

  displayedColumns: string[] = ['name', 'email', 'telephone', 'details'];

  constructor(public employeeService: EmployeeService, public dateService: DateService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.employeeService.getEmployees();

  }
  onAddEmployee() {
    this.employeeService.initializeAddEmployeeForm();
    this.dialog.open(AddEmployeeComponent);
  }

}
