<br>
<button mat-raised-button (click)="onCreate()">
    <mat-icon>add</mat-icon>&nbsp; Create new contract
</button>
<br>
<br>

<table mat-table [dataSource]="contracts" class="mat-elevation-z8">

    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

    <!-- Date Column -->
    <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef> Date </th>
        <td mat-cell *matCellDef="let contract"> {{dateService.formatDate(contract.date)}} </td>
    </ng-container>

    <!-- Device Column -->
    <ng-container matColumnDef="device">
        <th mat-header-cell *matHeaderCellDef> Device </th>
        <td mat-cell *matCellDef="let contract"> {{contract.device.manufacturer}} {{contract.device.model}} </td>
    </ng-container>

    <!-- Defect Column -->
    <ng-container matColumnDef="defect">
        <th mat-header-cell *matHeaderCellDef> Defect </th>
        <td mat-cell *matCellDef="let contract"> {{contract.device.defect}} </td>
    </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef> Status </th>
        <td mat-cell *matCellDef="let contract"> {{contract.status}} </td>
    </ng-container>

    <!-- Box Column -->
    <ng-container matColumnDef="box">
        <th mat-header-cell *matHeaderCellDef> Box </th>
        <td mat-cell *matCellDef="let contract"> {{contract.storageBox}} </td>
    </ng-container>

    <!-- Customer Column -->
    <ng-container matColumnDef="customer">
        <th mat-header-cell *matHeaderCellDef> Customer </th>
        <td mat-cell *matCellDef="let contract"> <a
                href="customer-details/{{contract.customerId}}">{{contract.customerId}}</a></td>
    </ng-container>

    <!-- Action Column -->
    <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef> Action </th>
        <td mat-cell *matCellDef="let contract">

            <button mat-button (click)="onEditContract(contract.id, contract.status)">
                <mat-icon>edit</mat-icon>&nbsp; Edit
            </button>
            <a href="/existing-customer-invoice/{{contract.customerId}}" mat-raised-button color="primary">
                <fa-icon [icon]="faInvoice"></fa-icon>&nbsp; Create Invoice
            </a>&nbsp;
            <a href="/generate-contract-receipt/{{contract.id}}" mat-raised-button color="primary">
                <fa-icon [icon]="faPDF"></fa-icon>&nbsp; View Contract
            </a>&nbsp;
            <button mat-raised-button color="warn" (click)="finish(contract.id)">
                <fa-icon [icon]="faCheck"></fa-icon>&nbsp; Finish
            </button>

        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>