import { EmployeeService } from './../../../services/employee.service';
import { ContractService } from './../../../services/contract.service';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from 'src/app/services/notification.service';
import { NewCustomerComponent } from '../../customer/new-customer/new-customer.component';

@Component({
  selector: 'add-employee',
  templateUrl: './add-employee.component.html',
  styleUrls: ['./add-employee.component.scss']
})
export class AddEmployeeComponent implements OnInit {

  constructor(
    public employeeService: EmployeeService,
    public dialogRef: MatDialogRef<AddEmployeeComponent>,
    public notificationService: NotificationService
  ) { }

  ngOnInit(): void {
  }
  onClear() {
    this.employeeService.addEmployeeForm.reset();
    this.employeeService.initializeAddEmployeeForm();
    this.notificationService.success('Customer Data submitted successfully');
  }

  onClose() {
    this.employeeService.addEmployeeForm.reset();
    this.employeeService.initializeAddEmployeeForm();
    this.dialogRef.close();
  }

  onAddEmployee() {
    this.employeeService.addEmployee(this.employeeService.addEmployeeForm.value);
    this.employeeService.addEmployeeForm.reset();
    this.employeeService.initializeAddEmployeeForm();
    this.notificationService.success('Data for new employee submitted successfully');
    this.onClose();
  }

}
