<br>
<a href="/product-form" mat-raised-button color="primary"><fa-icon [icon]="faPlus"></fa-icon>&nbsp; Create new Product</a>
<br>
<h2>List of Products</h2>
<br>
<i class="fas fa-file-contract"></i>
<table mat-table [dataSource]="ProductService.products" class="mat-elevation-z8">

    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

    <!-- Description Column -->
    <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef> Description </th>
        <td mat-cell *matCellDef="let product"> {{product.description}} </td>
    </ng-container>

    <!-- Price Column -->
    <ng-container matColumnDef="price">
        <th mat-header-cell *matHeaderCellDef> Price </th>
        <td mat-cell *matCellDef="let product"> {{product.price}}€ </td>
    </ng-container>

    <!-- Update Column -->
    <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef> Update </th>
        <td mat-cell *matCellDef="let product">
            <a href="/product-update/{{product.id}}" mat-raised-button color="primary"><fa-icon [icon]="faUpdate"></fa-icon>&nbsp; Update Product</a>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>