import { ContractService } from './../../../services/contract.service';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from 'src/app/services/notification.service';
import { NewCustomerComponent } from '../../customer/new-customer/new-customer.component';

@Component({
  selector: 'app-new-contract',
  templateUrl: './new-contract.component.html',
  styleUrls: ['./new-contract.component.scss']
})
export class NewContractComponent implements OnInit {

  constructor(
    public contractService: ContractService, 
    public dialogRef: MatDialogRef<NewCustomerComponent>,
    public notificationService: NotificationService
    ) { }

  ngOnInit(): void {
  }
  onClear() {
    this.contractService.form.reset();
    this.contractService.initializeFormGroup();
    this.notificationService.success('Contract Data submitted successfully');
  }

  onClose() {
    this.contractService.form.reset();
    this.contractService.initializeFormGroup();
    this.dialogRef.close();
  }

  onSubmit() {
    this.contractService.addContract(this.contractService.form.value);
    this.contractService.form.reset();
    this.contractService.initializeFormGroup();
    this.notificationService.success('Contract and Customer data submitted successfully');
    this.onClose();
  }

}
