import { liteInvoice } from './../models/liteInvoice.model';
import { HtmlInvoice } from 'src/app/models/htmlInvoice.model';
import { LoginService } from './login.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Invoice } from '../models/invoice.model';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {
  invoice!: Invoice;
  unpaidInvoices: liteInvoice[] = [];
  readonly rootURL = environment.apiUrl;

  constructor(public httpClient: HttpClient, private fb: FormBuilder, public loginService: LoginService, private router: Router) { }


  getInvoice(id: number) {
    return this.httpClient.get<any>(this.rootURL + '/invoice/' + id, { headers: this.loginService.getAuthHeader() });
  }

  getInvoiceHtml(id: number): Observable<HtmlInvoice> {
    return this.httpClient.get<HtmlInvoice>(this.rootURL + '/invoice/html/' + id, { headers: this.loginService.getAuthHeader() });
  }


  getLatestInvoices() {
    return this.httpClient.get<any>(this.rootURL + '/invoice/getLatestInvoices', { headers: this.loginService.getAuthHeader() });
  }
  getUnpaidInvoices() {
    this.httpClient.get<any>(this.rootURL + '/invoice/getUnpaidInvoices', { headers: this.loginService.getAuthHeader() }).subscribe(
      response => {
        console.log(response);
        this.unpaidInvoices = response;
      }
    );
  }

  createReturnInvoice(id: number) {
    console.log(this.rootURL + '/invoice/createReturnInvoice/' + id);
    return this.httpClient.get<any>(this.rootURL + '/invoice/createReturnInvoice/' + id, { headers: this.loginService.getAuthHeader() });
    //TODO redirect to return inv
  }

  sendInvoiceEmail(id: number) {
    console.log(id)
    this.httpClient.get(this.rootURL + '/invoice/email/' + id, { headers: this.loginService.getAuthHeader() }).subscribe(
      (result) => {
        console.log("result", result)
      }
    );
  }

  sendPaymentReminder(id: number) {
    console.log(id)
    this.httpClient.get(this.rootURL + '/invoice/reminder/' + id, { headers: this.loginService.getAuthHeader() }).subscribe(
      (result) => {
        console.log("result", result)
      }
    );
  }

  markPaid(id: number) {
    console.log(id)
    this.httpClient.get(this.rootURL + '/invoice/markPaid/' + id, { headers: this.loginService.getAuthHeader() }).subscribe(
      (result) => {
        console.log("result", result)
        window.location.reload()
      }
    );
  }

  postInvoice(formData: any) {
    console.log(formData)
    this.httpClient.post(this.rootURL + '/invoice/postNewCustomerInvoice/', formData, { headers: this.loginService.getAuthHeader() }).subscribe(
      (result) => {
        console.log("result", result)
        this.router.navigate(['/generate-invoice/' + result.toString()]);
      }
    );
  }

  postExistingCustomerInvoice(formData: any) {
    console.log(formData)
    this.httpClient.post(this.rootURL + '/invoice/postExistingCustomerInvoice/', formData, { headers: this.loginService.getAuthHeader() }).subscribe(
      (result) => {
        console.log("result", result)
        this.router.navigate(['/generate-invoice/' + result.toString()]);
      }
    );
  }
  //TODO put
  putProduct(id: string, formData: Invoice) {
    console.log(formData)
    this.httpClient.put(this.rootURL + '/invoice/' + id, formData, { headers: this.loginService.getAuthHeader() }).subscribe(
      (result) => {
        console.log("result", result)
      }
    );
  }

  downloadInvoiceByDate(start: string, end: string) {
    console.log("requesting invoies from start: "+start+" to end: "+end)
    return this.httpClient
          .get( this.rootURL + '/invoice/zip/range?start='+start+'&end='+end, {headers: this.loginService.getAuthHeader(), responseType: 'arraybuffer'})
  }
}
          