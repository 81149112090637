import { Call } from './../models/call.model';
import { InitCall } from './../models/initCall.mode';
import { LoginService } from './login.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SipUser } from '../models/sipUser.model';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class PhoneService {

  calls: Call[] = [];

  constructor(public httpClient: HttpClient, public loginService: LoginService) { }

  getCalls() {
    this.httpClient.get<Call[]>(this.loginService.getBackendUrl() + '/phone', { headers: this.loginService.getAuthHeader() }).subscribe(
      response => {
        console.log(response);
        this.calls = response;
      }
    );
  }

  getSipUsers(): Observable<SipUser[]> {
    return this.httpClient.get<any>(this.loginService.getBackendUrl() + '/phone/sipUsers', { headers: this.loginService.getAuthHeader() });
  }

  initiateCall(call: InitCall) {
    console.log(call);
  }
}
