import { SettingsService } from './../../../services/settings.service';
import { DateService } from './../../../services/date.service';
import { WoocommerceService } from './../../../services/woocommerce.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-woocommerce-orders',
  templateUrl: './woocommerce-orders.component.html',
  styleUrls: ['./woocommerce-orders.component.scss']
})
export class WoocommerceOrdersComponent implements OnInit {

  displayedColumns: string[] = ['number', 'date', 'amount', 'status', 'action'];

  constructor(public woocommerceService: WoocommerceService, public settingsService: SettingsService, public dateService: DateService) { }

  ngOnInit(): void {
    this.settingsService.checkWoocommerceApiKey()
    console.log("getting orders")
    this.woocommerceService.getOrders();
    
  }

}
