<mat-sidenav-container class="sidenav-container mat-app-background " [ngClass]="{'sap2-dark-theme': isDarkTheme}">
  <mat-sidenav #drawer class="sidenav" fixedInViewport [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="(isHandset$ | async) === false">
    <mat-toolbar><img src="/assets/logo.png" width="160"></mat-toolbar>
    <mat-nav-list>
      <a mat-list-item href="#">
        <fa-icon [icon]="faHome"></fa-icon>&nbsp; Home
      </a>
      <a mat-list-item href="new-invoice">
        <fa-icon [icon]="faInvoice"></fa-icon>&nbsp; Create Invoice
      </a>
      <a mat-list-item href="new-customer-cost-estimate">
        <fa-icon [icon]="faInvoice"></fa-icon>&nbsp; New Cost Estimate
      </a>
      <a mat-list-item href="contract">
        <fa-icon [icon]="faContract"></fa-icon>&nbsp; Contracts
      </a>
      <a mat-list-item href="customer">
        <fa-icon [icon]="faCustomer"></fa-icon>&nbsp; Customers
      </a>
      <a mat-list-item href="products">
        <fa-icon [icon]="faProduct"></fa-icon>&nbsp; Products
      </a>
      <div *ngIf="authService.checkEcommerceLicense()">
        <a mat-list-item href="ebay-auth">
          <mat-icon>price_check</mat-icon>&nbsp; Ebay Sales
        </a>
        <a mat-list-item href="woocommerce-orders">
          <mat-icon>woo_commerce</mat-icon>&nbsp; Woocommerce
        </a>
      </div>
      <a mat-list-item href="incoming-invoice">
        <mat-icon>receipt</mat-icon>&nbsp; Incoming Invoices
      </a>
      <a mat-list-item href="unpaid-invoices">
        <mat-icon>receipt</mat-icon>&nbsp; Unpaid Invoices
      </a>
      <a mat-list-item href="employee">
        <mat-icon>groups</mat-icon>&nbsp; Employees
      </a>
      <a mat-list-item href="income-statement">
        <mat-icon>price_check</mat-icon>&nbsp; Statistics
      </a>
      <a mat-list-item href="settings">
        <mat-icon>settings</mat-icon>&nbsp; Settings
      </a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()"
        *ngIf="isHandset$ | async">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <div *ngIf="this.isLoggedIn == false">
        <button mat-raised-button (click)="openLoginForm()">
          <mat-icon>person</mat-icon>&nbsp; Login
        </button>
        &nbsp; Please login to view content
      </div>
      <div *ngIf="this.isLoggedIn == true">
        <span>{{getLogin()}}</span>
        &nbsp;
        <button mat-raised-button (click)="openAccountSettings()">
          <mat-icon>settings</mat-icon>&nbsp; Account Settings
        </button>
        &nbsp;
        <button mat-raised-button (click)="logout()">
          <mat-icon>person</mat-icon>&nbsp; Logout
        </button>
        &nbsp;
        &nbsp;&nbsp;
        <mat-form-field>
          <mat-label>
            <mat-icon>search</mat-icon> Search
          </mat-label>
          <input matInput type="text" #searchInput (keyup)="superSearch($event)" placeholder="Find" />
        </mat-form-field>
        <div *ngIf="searchBarNotEmpty()">
          <ul class="filter-select">
            <li *ngFor="let res of searchResults" class="filter-select-list" (click)="searchClick(res.link)"> <span
                class="badge">{{res.type}} {{res.value}}</span></li>
          </ul>
        </div>
        &nbsp;
        &nbsp;
        <button mat-raised-button aria-label="Butten with bug icon" (click)="onFeedback()">
          <mat-icon>bug_report</mat-icon>&nbsp; Feedback
      </button>
      </div>
    </mat-toolbar>



    <router-outlet></router-outlet><!-- The Router Outlet contains the content of the selected page -->

  </mat-sidenav-content>
</mat-sidenav-container>