import { ContractService } from './../../../services/contract.service';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-existing-customer-contract',
  templateUrl: './existing-customer-contract.component.html',
  styleUrls: ['./existing-customer-contract.component.scss']
})
export class ExistingCustomerContractComponent implements OnInit {

  constructor(
    public contractService: ContractService, 
    public dialogRef: MatDialogRef<ExistingCustomerContractComponent>,
    public notificationService: NotificationService
    ) { }

  ngOnInit(): void {
  }
  onClear() {
    this.contractService.existingCustomerForm.reset();
    this.contractService.initializeExistingCustomerForm(0);
    this.notificationService.success('Contract Data');
  }

  onClose() {
    this.contractService.existingCustomerForm.reset();
    this.contractService.initializeExistingCustomerForm(0);
    this.dialogRef.close();
  }

  onSubmit() {
    this.contractService.addExistingCustomerContract(this.contractService.existingCustomerForm.value);
    this.contractService.existingCustomerForm.reset();
    this.contractService.initializeExistingCustomerForm(0);
    this.notificationService.success('Contract data submitted successfully');
    this.onClose();
  }

}
