import { DateService } from 'src/app/services/date.service';
import { InvoiceService } from './../../../services/invoice.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-unpaid-invoices',
  templateUrl: './unpaid-invoices.component.html',
  styleUrls: ['./unpaid-invoices.component.scss']
})
export class UnpaidInvoicesComponent implements OnInit {

  constructor(public invoiceService: InvoiceService, public dateService: DateService) { }

  displayedColumns: string[] = ['date', 'customer', 'invoice', 'reminder', 'markPaid'];

  ngOnInit(): void {
    this.invoiceService.getUnpaidInvoices();
  }

  sendPaymentReminder(inv: number): void {
    if (confirm("Are you sure to send a payment reminder with an invoice PDF copy to the customer?")) {
      console.log("sending payment reminder for invoice: " + inv)
      this.invoiceService.sendPaymentReminder(inv);
    }
  }

  markPaid(inv: number): void {
    if (confirm("Are you sure to mark this invoice as paid? Thi can not be reverted!!?")) {
      console.log("marking invoice as paid for invoice: " + inv)
      this.invoiceService.markPaid(inv);
    }
  }

}
