import { ExistingCustomerContractComponent } from './../contract/existing-customer-contract/existing-customer-contract.component';
import { ContractService } from './../../services/contract.service';
import { UpdateCustomerComponent } from './update-customer/update-customer.component';
import { NewCustomerComponent } from './new-customer/new-customer.component';
import { CustomerService } from './../../services/customer.service';
import { Component, ComponentFactoryResolver, OnInit } from '@angular/core';
import { Customer } from 'src/app/models/customer.model';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import {MatDialog} from '@angular/material/dialog';
import { Address } from 'src/app/models/address.model';
import { UpdateAddressComponent } from './update-address/update-address.component';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss']
})
export class CustomerComponent implements OnInit {

  //icons
  faDetails=faSearch

  searchName!: string;
  customers!: Customer[];
  displayedColumns: string[] = ['iD', 'name', 'address', 'action'];

  constructor(public CustomerService: CustomerService, public contractService: ContractService, private dialog: MatDialog ) { }

  ngOnInit(): void {
  }
  searchCustomer(name: any){
    console.log(name.target.value)
    if(name.target.value.length >= 2){  //only search if at least 2 chars are typed to reduce response size
      this.CustomerService.searchCustomer(name.target.value).subscribe(
        response => {
          console.log(response);
          this.customers = response;
        }
      )
    }
  }

  onCreate() {
    this.CustomerService.initializeFormGroup();
    this.dialog.open(NewCustomerComponent);
  }
  onEdit(id: number, customer: Customer){
    this.CustomerService.populateUpdateForm(customer);
    console.log(customer)
    this.dialog.open(UpdateCustomerComponent);
  }
  onEditAddress(id: number, address: Address){
    this.CustomerService.populateAddressForm(address);
    console.log(address)
    this.dialog.open(UpdateAddressComponent);
  }

  onCreateContract(c: number) {
    this.contractService.initializeExistingCustomerForm(c);
    this.dialog.open(ExistingCustomerContractComponent);
  }

}
