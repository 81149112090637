<div *ngIf="htmlInvoice$ !== undefined">

    <br>
    <button mat-raised-button printSectionId="print-section" ngxPrint>print</button>&nbsp;
    <a href="/generate-shipping-invoice/{{htmlInvoice$.invoiceID}}" mat-stroked-button color="primary">
        <mat-icon>local_shipping</mat-icon>&nbsp; Add Shipping Label
    </a>
    &nbsp;
    <button mat-raised-button type="button" color="warn" (click)="createReturnInvoice()">
        <mat-icon>assignment_return</mat-icon>&nbsp; Create Return Invoice
    </button><!-- TODO Only show button if customer Email is known-->
    &nbsp;
    <button *ngIf="htmlInvoice$.customer.email != ''" mat-raised-button type="button" (click)="sendInvoiceEmail()">
        <mat-icon>email</mat-icon>&nbsp; Send Invoice via Email
    </button>
    <!-- *nfif="invoice.invoice.type =='Rechnung'"  fix this ngif - not working because data from request comes in after page is rendered. So the button is not shown becaus invoice is empty at page load-->

    <html>
    <div id="print-section">

        <head>
            <meta charset="utf-8" />
            <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta2/dist/css/bootstrap.min.css" rel="stylesheet"
                integrity="sha384-BmbxuPwQa2lc/FVzBcNJ7UAyJxM6wuqIj61tLrc4wSX0szH/Ev+nYRRuWlolflfl"
                crossorigin="anonymous">
            <!-- A4 CSS -->
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/paper-css/0.3.0/paper.css">

            <title>Invoice</title>
        </head>

        <!-- Set "A5", "A4" or "A3" for class name -->
        <!-- Set also "landscape" if you need -->

        <body class="A4">


            <!-- Each sheet element should have the class "sheet" -->
            <!-- "padding-**mm" is optional: you can set 10, 15, 20 or 25 -->
            <section class="sheet padding-15mm">

                <!-- Write HTML just like a web page -->
                <div [innerHtml]="htmlInvoice$.html"></div>

                <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta2/dist/js/bootstrap.bundle.min.js"
                    integrity="sha384-b5kHyXgcpbZJO/tY9Ul7kGkf1S0CWuKcCD38l8YkeH8z8QjE0GmW1gYU5S9FOnJ0"
                    crossorigin="anonymous"></script>
            </section>
        </body>
    </div>

    </html>
</div>
<div *ngIf="htmlInvoice$ === undefined">
    invoice is beeing loaded please wait... If the invoice does not load try the following steps:
    <ul>
        <li>Ensure the invoice exists</li>
        <li>Reload this webpage</li>
        <li>Logut and login to your xedap instance</li>
        <li>If the two options above do not work wite a bug report and contact our customer supprot at <a
                href="mailto:info@xedap.de">info@xedap.de</a></li>
    </ul>
</div>