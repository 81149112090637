import { IncomingInvoiceService } from './../../../services/incoming-invoice.service';
import { Component, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/services/notification.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-incoming-invoice',
  templateUrl: './confirm-incoming-invoice.component.html',
  styleUrls: ['./confirm-incoming-invoice.component.scss']
})
export class ConfirmIncomingInvoiceComponent implements OnInit {

  constructor(
    public incomingInvoiceService: IncomingInvoiceService, 
    public dialogRef: MatDialogRef<ConfirmIncomingInvoiceComponent>,
    public notificationService: NotificationService
    ) { }

  ngOnInit(): void {
  }

  onClear() {
    this.incomingInvoiceService.confirmInvoiceForm.reset();
    this.incomingInvoiceService.initializeConfirmInvoiceForm();
    this.notificationService.success('Invoice stored successfully');
  }

  onClose() {
    this.incomingInvoiceService.confirmInvoiceForm.reset();
    this.incomingInvoiceService.initializeConfirmInvoiceForm();
    this.dialogRef.close();
  }

  onSubmit() {
    this.incomingInvoiceService.confirmInvoice(this.incomingInvoiceService.confirmInvoiceForm.value);
    this.incomingInvoiceService.confirmInvoiceForm.reset();
    this.incomingInvoiceService.initializeConfirmInvoiceForm();
    this.notificationService.success('Invoice stored successfully');
    this.onClose();
  }

}
