import { ExistingCustomerCostEstimateComponent } from './pages/cost-estimate/existing-customer-cost-estimate/existing-customer-cost-estimate.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { NewCustomerComponent } from './pages/customer/new-customer/new-customer.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavComponent } from './nav/nav.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { ProductsComponent } from './pages/products/products.component';
import { HttpClientModule } from '@angular/common/http';
import { ProductFormComponent } from './pages/products/product-form/product-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomerComponent } from './pages/customer/customer.component';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatInputModule } from '@angular/material/input';
import { ProductUpdateFormComponent } from './pages/products/product-update-form/product-update-form.component';
import { InvoiceComponent } from './pages/invoice/invoice.component';
import { NewInvoiceComponent } from './pages/invoice/new-invoice/new-invoice.component';
import { ContractComponent } from './pages/contract/contract.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CustomerDetailsComponent } from './pages/customer/customer-details/customer-details.component';
import { MatCardModule } from '@angular/material/card';
import { registerLocaleData } from '@angular/common';
import localeDE from '@angular/common/locales/de';
import {MatTabsModule} from '@angular/material/tabs';
import { NewContractComponent } from './pages/contract/new-contract/new-contract.component';
import { UpdateCustomerComponent } from './pages/customer/update-customer/update-customer.component';
import { GenerateInvoiceComponent } from './pages/invoice/generate-invoice/generate-invoice.component';
import { NgxPrintModule } from 'ngx-print';
import { UpdateAddressComponent } from './pages/customer/update-address/update-address.component';
import { ExistingCustomerInvoiceComponent } from './pages/invoice/existing-customer-invoice/existing-customer-invoice.component';
import { ExistingCustomerContractComponent } from './pages/contract/existing-customer-contract/existing-customer-contract.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { GenerateShippingInvoiceComponent } from './pages/invoice/generate-shipping-invoice/generate-shipping-invoice.component';
import { GenerateContractReceiptComponent } from './pages/contract/generate-contract-receipt/generate-contract-receipt.component';
import { HomeComponent } from './pages/home/home.component';
import {MatGridListModule} from '@angular/material/grid-list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { EditContractComponent } from './pages/contract/edit-contract/edit-contract.component';
import { IncomingInvoiceComponent } from './pages/incoming-invoice/incoming-invoice.component';
import {MatTreeModule} from '@angular/material/tree';
import { EmployeeComponent } from './pages/employee/employee.component';
import { EmployeeDetailsComponent } from './pages/employee/employee-details/employee-details.component';
import { GeneratePayrollComponent } from './pages/employee/generate-payroll/generate-payroll.component';
import { LoginComponent } from './pages/login/login.component';
import { EditAccountComponent } from './pages/login/edit-account/edit-account.component';
import { AddUserComponent } from './pages/login/add-user/add-user.component';
import { UserManagerComponent } from './pages/login/user-manager/user-manager.component';
import { AddEmployeeComponent } from './pages/employee/add-employee/add-employee.component';
import { EbayAuthComponent } from './pages/ebay/ebay-auth/ebay-auth.component';
import { EbaySalesComponent } from './pages/ebay/ebay-sales/ebay-sales.component';
import { UploadInvoiceComponent } from './pages/incoming-invoice/upload-invoice/upload-invoice.component';
import { WoocommerceOrdersComponent } from './pages/woocommerce/woocommerce-orders/woocommerce-orders.component';
import { WoocommerceProductsComponent } from './pages/woocommerce/woocommerce-products/woocommerce-products.component';
import { WoocommerceUpdateProductComponent } from './pages/woocommerce/woocommerce-update-product/woocommerce-update-product.component';
import { WoocommerceOrderDetailsComponent } from './pages/woocommerce/woocommerce-order-details/woocommerce-order-details.component';
import { GenerateCostEstimateComponent } from './pages/cost-estimate/generate-cost-estimate/generate-cost-estimate.component';
import { NewCustomerCostEstimateComponent } from './pages/cost-estimate/new-customer-cost-estimate/new-customer-cost-estimate.component';
import { ConfirmIncomingInvoiceComponent } from './pages/incoming-invoice/confirm-incoming-invoice/confirm-incoming-invoice.component';
import { InitiateCallComponent } from './pages/phone/initiate-call/initiate-call.component';
import { IncomeStatementComponent } from './pages/stats/income-statement/income-statement.component';
import { UnpaidInvoicesComponent } from './pages/invoice/unpaid-invoices/unpaid-invoices.component';
import { EditEmployeeComponent } from './pages/employee/edit-employee/edit-employee.component';
import { FeedbackComponent } from './pages/feedback/feedback.component';


registerLocaleData(localeDE, 'de');

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    ProductsComponent,
    ProductFormComponent,
    CustomerComponent,
    ProductUpdateFormComponent,
    InvoiceComponent,
    NewInvoiceComponent,
    ContractComponent,
    CustomerDetailsComponent,
    NewCustomerComponent,
    NewContractComponent,
    UpdateCustomerComponent,
    GenerateInvoiceComponent,
    UpdateAddressComponent,
    ExistingCustomerInvoiceComponent,
    ExistingCustomerContractComponent,
    SettingsComponent,
    GenerateShippingInvoiceComponent,
    GenerateContractReceiptComponent,
    HomeComponent,
    EditContractComponent,
    IncomingInvoiceComponent,
    EmployeeComponent,
    EmployeeDetailsComponent,
    GeneratePayrollComponent,
    LoginComponent,
    EditAccountComponent,
    AddUserComponent,
    UserManagerComponent,
    AddEmployeeComponent,
    EbayAuthComponent,
    EbaySalesComponent,
    UploadInvoiceComponent,
    WoocommerceOrdersComponent,
    WoocommerceProductsComponent,
    WoocommerceUpdateProductComponent,
    WoocommerceOrderDetailsComponent,
    GenerateCostEstimateComponent,
    NewCustomerCostEstimateComponent,
    ExistingCustomerCostEstimateComponent,
    ConfirmIncomingInvoiceComponent,
    InitiateCallComponent,
    IncomeStatementComponent,
    UnpaidInvoicesComponent,
    EditEmployeeComponent,
    FeedbackComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatSelectModule,
    MatCheckboxModule,
    MatChipsModule,
    MatInputModule,
    FontAwesomeModule,
    MatCardModule,
    MatTabsModule,
    MatDialogModule,
    MatSnackBarModule,
    NgxPrintModule,
    MatGridListModule,
    MatTooltipModule,
    MatSlideToggleModule,
    FormsModule,
    MatTreeModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
