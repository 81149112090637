import { LoginService } from './login.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Employee } from '../models/employee.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  employees: Employee[] = [];

  constructor(public httpClient: HttpClient, public loginService: LoginService) { }

  addEmployeeForm: FormGroup = new FormGroup({
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    street: new FormControl('', Validators.required),
    streetNumber: new FormControl('', Validators.required),
    postalCode: new FormControl('', Validators.required),
    city: new FormControl('', Validators.required),
    email: new FormControl('', Validators.compose([Validators.required, Validators.email])),
    telephone: new FormControl('', Validators.required),
    loan: new FormControl('', Validators.required),
    bankAccountOwner: new FormControl(''),
    bank: new FormControl(''),
    iban: new FormControl(''),
    bic: new FormControl(''),
    privateInsurance: new FormControl(''),
    taxNumber: new FormControl(''),
    pensionInsuranceNumber: new FormControl(''),
    fullPensionInsurance: new FormControl(''),
  });

  editEmployeeForm: FormGroup = new FormGroup({
    id: new FormControl(''),
    active: new FormControl(''),
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    street: new FormControl('', Validators.required),
    streetNumber: new FormControl('', Validators.required),
    postalCode: new FormControl('', Validators.required),
    city: new FormControl('', Validators.required),
    email: new FormControl('', Validators.compose([Validators.required, Validators.email])),
    telephone: new FormControl('', Validators.required),
    loan: new FormControl('', Validators.required),
    bankAccountOwner: new FormControl(''),
    bank: new FormControl(''),
    iban: new FormControl(''),
    bic: new FormControl(''),
    privateInsurance: new FormControl(''),
    taxNumber: new FormControl(''),
    pensionInsuranceNumber: new FormControl(''),
    fullPensionInsurance: new FormControl(''),
  });

  getEmployees() {
    this.httpClient.get<any>(this.loginService.getBackendUrl() + '/Employee', { headers: this.loginService.getAuthHeader() }).subscribe(
      response => {
        console.log(response);
        this.employees = response;
      }
    );
  }
  getEmployee(id: number) {
    return this.httpClient.get<Employee>(this.loginService.getBackendUrl() + '/employee/' + id, { headers: this.loginService.getAuthHeader() });
  }
  getPensionInsuranceType(x: boolean) {
    if (x == false) {
      return "no"
    } else {
      return "yes"
    }
  }
  getInsuranceType(x: boolean) {
    if (x == false) {
      return "legally"
    } else {
      return "private"
    }
  }

  addEmployee(formData: any) {
    console.log(formData)
    this.httpClient.post(this.loginService.getBackendUrl() + '/employee/addEmployee', formData, { headers: this.loginService.getAuthHeader() }).subscribe(
      (result) => {
        console.log("result", result)
      }
    );
  }

  editEmployee(employee: Employee) {
    console.log(employee)
    this.httpClient.put(this.loginService.getBackendUrl() + '/employee', employee, { headers: this.loginService.getAuthHeader() }).subscribe(
      (result) => {
        console.log("result", result)
        window.location.reload()
      }
    );
  }

  initializeAddEmployeeForm() {
    this.addEmployeeForm.setValue({
      firstName: '',
      lastName: '',
      street: '',
      streetNumber: '',
      postalCode: '',
      city: '',
      email: '',
      telephone: '',
      loan: '',
      bankAccountOwner: '',
      bank: '',
      iban: '',
      bic: '',
      privateInsurance: false,
      taxNumber: '',
      pensionInsuranceNumber: false,
      fullPensionInsurance: ''
    });
  }

  initializeEditEmployeeForm() {
    this.editEmployeeForm.setValue({
      id: 0,
      active: true,
      firstName: '',
      lastName: '',
      street: '',
      streetNumber: '',
      postalCode: '',
      city: '',
      email: '',
      telephone: '',
      loan: '',
      bankAccountOwner: '',
      bank: '',
      iban: '',
      bic: '',
      privateInsurance: false,
      taxNumber: '',
      pensionInsuranceNumber: false,
      fullPensionInsurance: ''
    });
  }

  populateUpdateForm(employee: Employee) {
    console.warn(employee)
    this.editEmployeeForm.patchValue({
      id: employee.id,
      active: employee.active,
      firstName: employee.firstName,
      lastName: employee.lastName,
      street: employee.street,
      streetNumber: employee.streetNumber,
      postalCode: employee.postalCode,
      city: employee.city,
      email: employee.email,
      telephone: employee.telephone,
      loan: employee.loan,
      bankAccountOwner: employee.bankAccountOwner,
      bank: employee.bank,
      iban: employee.iban,
      bic: employee.bic,
      privateInsurance: employee.privateInsurance,
      taxNumber: employee.taxNumber,
      pensionInsuranceNumber: employee.pensionInsuranceNumber,
      fullPensionInsurance: employee.fullPensionInsurance
    });
  }
}
