import { WoocommerceProduct } from './../models/woocommerceProduct.model';
import { WoocommerceOrder } from './../models/woocommerceOrder';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoginService } from './login.service';
import { environment } from 'src/environments/environment';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class WoocommerceService {

  orders: WoocommerceOrder[] = [];
  products: WoocommerceProduct[] = [];


  constructor(public httpClient: HttpClient, public loginService: LoginService) { }

  editProductForm: FormGroup = new FormGroup({
    id: new FormControl(),
    slug: new FormControl('', Validators.required),
    premalink: new FormControl(''),
    description: new FormControl(''),
    short_description: new FormControl(''),
    price: new FormControl('', Validators.required),
    regular_price: new FormControl('', Validators.required),
    sale_price: new FormControl(''),
    on_sale: new FormControl(''),
    stock_quantity: new FormControl('', Validators.required),
  });

  getOrders() {
    console.log("requesting orders")
    this.httpClient.get<any>(this.loginService.getBackendUrl() + '/woocommerce/orders', { headers: this.loginService.getAuthHeader() }).subscribe(
      response => {
        console.log(response);
        this.orders = response;
      }
    );
  }

  getOrder(id: number) {
    console.log("requesting order with id: "+ id)
    return this.httpClient.get<any>(this.loginService.getBackendUrl() + '/woocommerce/order/'+id, { headers: this.loginService.getAuthHeader() });
  }

  getProducts() {
    console.log("requesting products")
    this.httpClient.get<any>(this.loginService.getBackendUrl() + '/woocommerce/products', { headers: this.loginService.getAuthHeader() }).subscribe(
      response => {
        console.log(response);
        this.products = response;
      }
    );
  }
  editProduct(product: WoocommerceProduct) {
    console.log("editing Product")
    this.httpClient.post<WoocommerceProduct>(this.loginService.getBackendUrl() + '/woocommerce/updateProduct',product, { headers: this.loginService.getAuthHeader() }).subscribe(
      response => {
        console.log(response);
        window.location.reload()
      }
    );
  }

  initializeEditProductForm() {
    this.editProductForm.setValue({
      id: 'none',
      slug: '',
      permalink: '',
      description: '',
      short_description: '',
      price: '',
      regular_price: '',
      sale_price: '',
      on_sale: false,
      stock_quantity: ''
    });
  }

  populateEditProductForm(product: WoocommerceProduct) {
    console.warn(product.id)
    this.editProductForm.patchValue({
      id: product.id,
      slug: product.slug,
      permalink: product.permalink,
      description:'',
      short_description: product.short_description,
      price: product.price,
      regular_price: product.regular_price,
      sale_price: product.sale_price,
      on_sale: product.on_sale,
      stock_quantity: product.stock_quantity
    });
  }
}