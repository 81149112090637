import { ProductService } from './../../services/product.service';
import { Component, OnInit } from '@angular/core';
import { faPencilAlt, faPlus } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {

  faUpdate = faPencilAlt;
  faPlus = faPlus;

  displayedColumns: string[] = ['description', 'price', 'action'];

  constructor(public ProductService:ProductService) { }


  ngOnInit(): void {
    this.ProductService.getProducts();
  }

}
