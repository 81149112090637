import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FeedbackService } from 'src/app/services/feedback.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})


export class FeedbackComponent implements OnInit {
  selectedValue!: string;
  
  constructor(
    public feedbackService: FeedbackService, 
    public dialogRef: MatDialogRef<FeedbackComponent>,
    public notificationService: NotificationService
    ) { }

  ngOnInit(): void {
  }
  onClear() {
    this.feedbackService.feedbackForm.reset();
    this.feedbackService.initializeFeedbackForm();
    this.notificationService.success('Feedback submitted successfully');
  }

  onClose() {
    this.feedbackService.feedbackForm.reset();
    this.feedbackService.initializeFeedbackForm();
    this.dialogRef.close();
  }

  onSubmit() {
    this.feedbackService.sendFeedback(this.feedbackService.feedbackForm.value);
    this.feedbackService.feedbackForm.reset();
    this.feedbackService.initializeFeedbackForm();
    this.notificationService.success('Feedback submitted successfully');
    this.onClose();
  }

}
