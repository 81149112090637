import { LoginService } from './login.service';
import { UpdateAddressComponent } from './../pages/customer/update-address/update-address.component';
import { Customer } from './../models/customer.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Address } from '../models/address.model';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(public httpClient: HttpClient, public loginService: LoginService, private router: Router) { }


  form: FormGroup = new FormGroup({
    id: new FormControl(),
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    street: new FormControl('', Validators.required),
    streetNumber: new FormControl('', Validators.required),
    postalCode: new FormControl('', Validators.required),
    city: new FormControl('', Validators.required),
    additionalInfo: new FormControl(''),
    country: new FormControl('Deutschland', Validators.required),
    email: new FormControl('', Validators.email),
    telephoneNumber: new FormControl('')
  });

  updateForm: FormGroup = new FormGroup({
    id: new FormControl(),
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    email: new FormControl(''),
    telephoneNumber: new FormControl('')
  });

  updateAddressForm: FormGroup = new FormGroup({
    id: new FormControl(),
    street: new FormControl('', Validators.required),
    streetNumber: new FormControl('', Validators.required),
    postalCode: new FormControl('', Validators.required),
    city: new FormControl('', Validators.required),
    additionalInfo: new FormControl(''),
    country: new FormControl('Deutschland', Validators.required),
  });



  searchCustomer(name: string) {
    return this.httpClient.get<any>(this.loginService.getBackendUrl() + '/customer/search/' + name, { headers: this.loginService.getAuthHeader() });
  }
  getCustomer(id: number) {
    return this.httpClient.get<Customer>(this.loginService.getBackendUrl() + '/customer/' + id, { headers: this.loginService.getAuthHeader() });
  }

  addCustomer(formData: any) {
    console.log(formData)
    this.httpClient.post(this.loginService.getBackendUrl() + '/customer/', formData, { headers: this.loginService.getAuthHeader() }).subscribe(
      (result) => {
        console.log("result", result)
      }
    );
  }

  updateCustomer(formData: any) {
    console.warn(formData)
    console.log(formData.id)
    this.httpClient.put(this.loginService.getBackendUrl() + '/customer/' + formData.id, formData, { headers: this.loginService.getAuthHeader() }).subscribe(
      (result) => {
        console.log("result", result)
        window.location.reload()
      }
    );
  }

  updateAddress(formData: any) {
    console.warn(formData)
    console.log(formData.id)
    this.httpClient.put(this.loginService.getBackendUrl() + '/customer/editAddress/' + formData.id, formData, { headers: this.loginService.getAuthHeader() }).subscribe(
      (result) => {
        console.log("result", result)
        window.location.reload()
      }
    );
  }

  populateForm(customer: Customer) {
    console.warn(customer)
    //this.form.setValue(_.omit(customer));
    this.form.patchValue({
      id: customer.id,
      firstName: customer.firstName,
      lastName: customer.lastName,
      street: customer.address.street,
      streetNumber: customer.address.streetNumber,
      additionalInfo: customer.address.additionalInfo,
      postalCode: customer.address.postalCode,
      city: customer.address.city,
      country: customer.address.country,
      email: customer.email,
      telephoneNumber: customer.telephoneNumber
    });
  }

  populateUpdateForm(customer: Customer) {
    console.warn(customer)
    this.updateForm.patchValue({
      id: customer.id,
      firstName: customer.firstName,
      lastName: customer.lastName,
      email: customer.email,
      telephoneNumber: customer.telephoneNumber
    });
  }

  populateAddressForm(address: Address) {
    console.warn(address)
    this.updateAddressForm.patchValue({
      id: address.id,
      street: address.street,
      streetNumber: address.streetNumber,
      additionalInfo: address.additionalInfo,
      postalCode: address.postalCode,
      city: address.city,
      country: address.country,
    });
  }



  initializeFormGroup() {
    this.form.setValue({
      id: 'none',
      firstName: '',
      lastName: '',
      street: '',
      streetNumber: '',
      postalCode: '',
      additionalInfo: '',
      city: '',
      country: 'Deutschland',
      email: '',
      telephoneNumber: ''
    });
  }
}

