import { ProductService } from './../../../services/product.service';
import { InvoiceService } from './../../../services/invoice.service';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { faMinus, faPaperPlane, faPlus } from '@fortawesome/free-solid-svg-icons';
import { DateService } from 'src/app/services/date.service';

@Component({
  selector: 'app-new-invoice',
  templateUrl: './new-invoice.component.html',
  styleUrls: ['./new-invoice.component.scss']
})
export class NewInvoiceComponent implements OnInit {

  invoiceForm!: FormGroup;
  products$: any;

  //icons
  faPlus=faPlus;
  faMinus=faMinus;
  faSubmit=faPaperPlane;

  // Form state
  loading = false;
  success = false;

  constructor(public InvoiceService:InvoiceService, private fb:FormBuilder, public dateService: DateService, public productService: ProductService) { }

  ngOnInit(): void {
    this.invoiceForm = this.fb.group({
      firstName: '',
      lastName: '',
      street: '',
      streetNumber: '',
      additionalInfo: '',
      postalCode: '',
      city: '',
      country:'Deutschland',
      email: '',
      telephoneNumber: '',
      invoiceDate: this.dateService.getDate(),
      performanceDate: this.dateService.getDate(),
      discount: 0,
      shippingCost: 0,
      isPaid: true,
      sendMail: true,
      warranty: 'Normal',
      items: this.fb.array([])
    })

    this.products$ = this.getSelectProduct();

    
  }
  get itemForms() {
    return this.invoiceForm.get('items') as FormArray
  }
  
  addItem() {
    const item = this.fb.group({ 
      id: [],
      amount: [1],
      diffPrice: []
    })
  
    this.itemForms.push(item);
  }
  
  deleteItem(i:number) {
    this.itemForms.removeAt(i)
  }
  async submitHandler() {
    this.loading = true;
    const formData = this.invoiceForm.value;
    console.warn(formData);

    try {
      this.InvoiceService.postInvoice(formData)
      this.success = true;
    } catch(err) {
      console.error(err)
    }

    this.loading = false;
    
  }

  getSelectProduct(): any{  
    return this.productService.getSelectProducts()
  }

}
