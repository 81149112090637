<h2>Create new Product</h2>
<form [formGroup] = "productForm" [hidden]="success" (ngSubmit)="submitHandler()"> 
    <br>
    <mat-form-field>
        <input matInput placeholder="Article ID"  formControlName="id">
    </mat-form-field>
    <br>
    <mat-form-field>
        <input matInput placeholder="Description"  formControlName="description">
    </mat-form-field>
    <br>
    <mat-form-field>
        <input matInput placeholder="Buy Net"  formControlName="buyNet">
    </mat-form-field>
    <br>
    <mat-form-field>
        <input matInput placeholder="Buy Gross"  formControlName="buyGross">
    </mat-form-field>
    <br>
    <mat-form-field>
        <input matInput placeholder="Margin"  formControlName="margin">
    </mat-form-field>
    <br>
    <mat-form-field>
        <input matInput placeholder="Price (Net)"  formControlName="price" [(ngModel)]='netPrice'>
    </mat-form-field>
    &nbsp;
    <small>Gross Price: {{(netPrice*1.19).toFixed(2)}}</small>
    <br>
    <mat-checkbox formControlName= "isPublic" color ="primary">is Public</mat-checkbox>
    <br>
    <mat-form-field>
        <input matInput placeholder="urURL"  formControlName="url">
    </mat-form-field>
    <br>
    <mat-form-field>
        <input matInput placeholder="Stock Is"  formControlName="stockIs">
    </mat-form-field>
    <br>
    <mat-form-field>
        <input matInput placeholder="Stock Target"  formControlName="stockTarget">
    </mat-form-field>
    <br>

    <button mat-raised-button color="primary" type="submit" [disabled]="productForm.invalid"><fa-icon [icon]="faSubmit"></fa-icon>&nbsp; Submit Product</button>
</form>
<div *ngIf="success" class="notification is-success">
    Yay! We received your submission
</div>