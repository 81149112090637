import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor() { }

  //formats any datetime string to dd.MM.YYYY
  formatDate(d: any): any {
    const datepipe: DatePipe = new DatePipe("de-DE")
    let formattedDate = datepipe.transform(d, 'dd.MM.YYYY');
    //console.log(formattedDate)
    return formattedDate
  }

  //returns the actual date as string in form yyyy-MM-dd - used primarily to create invoices
  getDate() {
    const datepipe: DatePipe = new DatePipe("de-DE")
    return datepipe.transform(new Date(), 'yyyy-MM-dd');
  }
}
