import { Account } from './../models/account.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { User } from '../models/user.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  public account!: Account

  constructor(public httpClient: HttpClient) { }

  users: User[] = [];

  loginForm: FormGroup = new FormGroup({
    backend: new FormControl('', Validators.required),
    name: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required)
  });
  changePasswordForm: FormGroup = new FormGroup({
    username: new FormControl(),
    oldPassword: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required)
  });
  addNewUserForm: FormGroup = new FormGroup({
    username: new FormControl('', Validators.required),
    email: new FormControl('', Validators.compose([Validators.required, Validators.email])),
    admin: new FormControl()
  });

  initializeFormGroup() {
    this.loginForm.setValue({
      backend: 'http://dev.xedap.de:5000',
      name: '',
      password: '',
    });
  }
  initializeChangePasswordForm() {
    this.changePasswordForm.setValue({
      username: localStorage.getItem('username'),
      oldPassword: '',
      password: '',
    });
  } initializeAddNewUserForm() {
    this.addNewUserForm.setValue({
      username: '',
      email: '',
      admin: false
    });
  }
  login(formData: any) {
    localStorage.setItem('backend', formData.backend+"/api")
    console.log(formData)
    this.httpClient.post<Account>(this.getBackendUrl() + '/account/login/', formData).subscribe(
      (result) => {
        console.log("result", result)
        console.warn("ecommerce License: "+result.eCommerce)
        this.account = result
        localStorage.setItem('username', result.username)
        localStorage.setItem('token', result.value)
        localStorage.setItem('token_expires', result.expirationTime)
        localStorage.setItem('ECommerce', result.eCommerce.toString())
        localStorage.setItem('Phone', result.phone.toString())
        localStorage.setItem('Admin', result.admin.toString())
        window.location.reload()
      }
    );
  }

  getBackendUrl(): any{
      return localStorage.getItem('backend');
  }

  logout() {
    this.httpClient.get<any>(this.getBackendUrl() + '/account/login/', { headers: this.getAuthHeader() }).subscribe(
      response => {
        console.log(response); //always null???
      }
    );
    localStorage.removeItem('backend')
    localStorage.removeItem('username')
    localStorage.removeItem('token')
    localStorage.removeItem('token_expires')
    localStorage.removeItem('ECommerce')
    localStorage.removeItem('Phone')
    localStorage.removeItem('Admin')
    window.location.reload()
  }

  getAuthHeader() {
    return { 'Content-Type': 'application/json', 'token': (localStorage.getItem('token') || '').toString() }
  }

  changePassword(formData: any) {
    console.log(formData)
    this.httpClient.post<any>(this.getBackendUrl() + '/account/login/' + '/changePassword/', formData, { headers: this.getAuthHeader() }).subscribe(
      (result) => {
        console.log("result", result)
        this.logout()
      }
    );
  }
  addUser(formData: any) {
    console.log(formData)
    this.httpClient.post<any>(this.getBackendUrl() + '/account/login/' + '/addUser/', formData, { headers: this.getAuthHeader() }).subscribe(
      (result) => {
        console.log("result", result)
      }
    );
  }

  getUsers() {
    this.httpClient.get<any>(this.getBackendUrl() + '/account/login/' + '/getUsers', { headers: this.getAuthHeader() }).subscribe(
      response => {
        console.log(response);
        this.users = response;
      }
    );
  }

  removeUser(user: User) {
    console.log("removing user: " + user.Name)
    this.httpClient.post<any>(this.getBackendUrl() + '/account/login/' + '/removeUser/', user, { headers: this.getAuthHeader() }).subscribe(
      (result) => {
        console.log("result", result)
      }
    );
  }

  resetPassword(user: User) {
    console.log("resetting password for user: " + user.Name)
    this.httpClient.post<any>(this.getBackendUrl() + '/account/login/' + '/resetPassword/', user, { headers: this.getAuthHeader() }).subscribe(
      (result) => {
        console.log("result", result)
      }
    );
  }

}
