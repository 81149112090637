import { CostEstimateService } from './../../../services/cost-estimate.service';
import { htmlCostEstimate } from './../../../models/htmlCostEstimate.model';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-generate-cost-estimate',
  templateUrl: './generate-cost-estimate.component.html',
  styleUrls: ['./generate-cost-estimate.component.scss']
})
export class GenerateCostEstimateComponent implements OnInit {

  htmlEstimate$!: htmlCostEstimate;
  id!: any;

  constructor(public costEstimateService: CostEstimateService, private route: ActivatedRoute, private router: Router) { }

  printPage() {
    window.print();
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get("id")
    console.log(this.id);
    this.costEstimateService.getCostEstimateHtml(this.id).subscribe(
      response => {
        console.log(response);
        this.htmlEstimate$ = response;
      }
    )
  }

  sendCostEstimateEmail() {
    if (confirm("Are you sure to send the cost estimate " + this.htmlEstimate$.estimateId + " as PDF copy to the customer?")) {
      console.log("Sending cost estimate via email")
      this.costEstimateService.sendEstimateEmail(this.htmlEstimate$.estimateId)
    }
  }

}