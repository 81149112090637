import { InitiateCallComponent } from './../phone/initiate-call/initiate-call.component';
import { NavComponent } from './../../nav/nav.component';
import { liteInvoice } from './../../models/liteInvoice.model';
import { InvoiceService } from './../../services/invoice.service';
import { ContractService } from './../../services/contract.service';
import { Component, OnInit } from '@angular/core';
import { PhoneService } from 'src/app/services/phone.service';
import { MatTooltipDefaultOptions, MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { Contract } from 'src/app/models/contract.model';
import { DateService } from 'src/app/services/date.service';
import { MatDialog } from '@angular/material/dialog';
import { ignoreElements } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';

/** Custom options the configure the tooltip's default show/hide delays. */
export const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 500,
  hideDelay: 1000,
  touchendHideDelay: 1000,
};

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults }
  ],
})
export class HomeComponent implements OnInit {

  displayedPhoneColumns: string[] = ['date', 'number', 'action'];
  displayedContractColumns: string[] = ['device', 'defect', 'status', 'action'];
  displayedInvoicesColumns: string[] = ['date', 'customer', 'action'];
  contracts!: Contract[];
  latestInvoices!: liteInvoice[];

  constructor(public nav: NavComponent, public phoneService: PhoneService, public contractService: ContractService, public dateService: DateService, public invoiceService: InvoiceService, public dialog: MatDialog, public authService: AuthService) { }

  ngOnInit(): void {
    if (this.nav.isLoggedIn == false) { return }
    if (this.authService.checkPhoneLicense()) {
      this.phoneService.getCalls()
    }else
    {
      console.log("placetel calls not shown - license does not include call APIs")
    }

    this.contractService.getRecentContracts().subscribe(
      response => {
        console.log(response);
        this.contracts = response;
      }
    );;

    this.invoiceService.getLatestInvoices().subscribe(
      response => {
        console.log(response);
        this.latestInvoices = response;
      }
    );;
  }
  makeCall(nr: string) {
    console.log(nr)
    if (this.authService.checkPhoneLicense()) {
      this.dialog.open(InitiateCallComponent);
    }else{
      console.log("can not init a call - this is not included in your license")
    }
  }
}