<div *nfIf="order !== undefined">
<mat-grid-list cols="2" rowHeight="750">
    <mat-grid-tile>
        <mat-card class="order-card">
            <mat-card-header>
                <mat-card-title>Order Details</mat-card-title>
                <mat-card-subtitle>ID: {{order.id}}</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <h2>Order data:</h2>
                Order Date: {{order.date_created_gmt}}<br>
                <h3>Billing details</h3>
                <p>
                    {{order.billing.first_name}} {{order.billing.last_name}}<br>
                    {{order.billing.address_1}} <br>
                    {{order.billing.address_2}} <br>
                    {{order.billing.postcode}}, {{order.billing.city}} <br>
                    {{order.billing.country}}<br>
                    {{order.billing.email}}<br>
                    {{order.billing.phone}}<br>
                </p>
                <h4>Items</h4>
                <li *ngFor="let item of order.lineItems">
                    {{item.quantity}}x &nbsp;{{item.name}}
                </li>
                <li>Shipping cost : {{order.shipping_total}}€ ( plus {{order.shipping_tax}}€ taxes)</li>
                <br>
            </mat-card-content>
            <mat-card-actions>
                <a href="" mat-button color="primary">
                    <mat-icon>add</mat-icon>&nbsp; Create Invoice
                </a>&nbsp;
            </mat-card-actions>
        </mat-card>
    </mat-grid-tile>
    <mat-grid-tile>
        <mat-card class="shipping-card">
            <mat-card-header>
                <mat-card-title>Shipping Details</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <h3>Shipping - Address</h3>
                {{order.shipping.first_name}} {{order.shipping.last_name}}<br>
                {{order.shipping.address_1}} <br>
                {{order.shipping.address_2}} <br>
                {{order.shipping.postcode}}, {{order.shipping.city}} <br>
            </mat-card-content>
            <mat-card-actions>
                <a href="" mat-button>
                    <mat-icon>add</mat-icon>&nbsp; Generate shipping label
                </a>&nbsp;
            </mat-card-actions>
        </mat-card>
    </mat-grid-tile>
</mat-grid-list>
</div>
<div *ngIf="order === undefined">
    order is beeing loaded please wait... If the order does not load try the following steps:
    <ul>
        <li>Ensure the order exists</li>
        <li>Reload this webpage</li>
        <li>Logut and login to your xedap instance</li>
        <li>If the two options above do not work wite a bug report and contact our customer supprot at <a
                href="mailto:info@xedap.de">info@xedap.de</a></li>
    </ul>
</div>