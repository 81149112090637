<div *ngIf="employee !== undefined">
    <mat-grid-list cols="2" rowHeight="750" rowWidth="300">
        <mat-grid-tile>
            <mat-card class="employee-card">
                <mat-card-header>
                    <div mat-card-avatar class="example-header-image"></div>
                    <mat-card-title>{{employee.firstName}} {{employee.lastName}}</mat-card-title>
                    <mat-card-subtitle>Employee number: {{employee.id}}</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                    <h2>Address:</h2>
                    <p>
                        {{employee.street}} {{employee.streetNumber}}<br>
                        {{employee.postalCode}} {{employee.city}}<br>
                    </p>
                    <br>
                    <h2>Contact information:</h2>
                    <p>
                        E-Mail: <a href="mailto:{{employee.email}}">{{employee.email}}</a><br>
                        Tel.: {{employee.telephone}}<br>
                    </p>
                    <br>
                    <h2>Bank account info:</h2>
                    <p>
                        Bank Account Owner: {{employee.bankAccountOwner}}<br>
                        Bank.: {{employee.bank}}<br>
                        IBAN: {{employee.iban}}<br>
                        BIC.: {{employee.bic}}<br>
                    </p>
                    <br>
                    <h2>Additional legal informations:</h2>
                    <p>
                        Insurance type: {{employeeService.getInsuranceType(employee.privateInsurance)}}<br>
                        Full pension onsurance.:
                        {{employeeService.getPensionInsuranceType(employee.fullPensionInsurance)}}<br>
                        Pension insurance number: {{employee.pensionInsuranceNumber}}<br>
                    </p>
                    <br>
                </mat-card-content>
                <mat-card-actions>
                    <button mat-button (click)="onEdit(employee)">
                        <mat-icon>manage_accounts</mat-icon>&nbsp; Edit Employee
                    </button>
                </mat-card-actions>
            </mat-card>
        </mat-grid-tile>
        <mat-grid-tile>
            <mat-card class="payroll-card">
                <mat-card-header>
                    <div mat-card-avatar>
                        <mat-icon>checklist</mat-icon>
                    </div>
                    <mat-card-title>Payrolls
                    </mat-card-title>
                    <mat-card-subtitle>Payrolls of the last year</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                    <table mat-table [dataSource]="payrollService.payrolls" class="mat-elevation-z8">

                        <!--- Note that these columns can be defined in any order.
                      The actual rendered columns are set as a property on the row definition" -->

                        <!-- month Column -->
                        <ng-container matColumnDef="month">
                            <th mat-header-cell *matHeaderCellDef> Month </th>
                            <td mat-cell *matCellDef="let payroll"> {{payroll.month}} &nbsp;&nbsp; </td>
                        </ng-container>

                        <!-- hours Column -->
                        <ng-container matColumnDef="hours">Hours
                            <th mat-header-cell *matHeaderCellDef> Hours </th>
                            <td mat-cell *matCellDef="let payroll"> {{payroll.hours}}&nbsp;&nbsp; </td>
                        </ng-container>

                        <!-- sum net income Column -->
                        <ng-container matColumnDef="sumNet">
                            <th mat-header-cell *matHeaderCellDef> Loan Gross </th>
                            <td mat-cell *matCellDef="let payroll"> {{payroll.sumGross}}€&nbsp;&nbsp; </td>
                        </ng-container>

                        <!-- sum expenditure Column -->
                        <ng-container matColumnDef="sumExpenditure">
                            <th mat-header-cell *matHeaderCellDef> Expenditure </th>
                            <td mat-cell *matCellDef="let payroll"> {{payroll.sumExpenditure}}€&nbsp;&nbsp; </td>
                        </ng-container>

                        <!-- action Column -->
                        <ng-container matColumnDef="link">
                            <th mat-header-cell *matHeaderCellDef> Details </th>
                            <td mat-cell *matCellDef="let payroll">
                                <a href="/generate-payroll/{{payroll.id}}" mat-raised-button color="primary">
                                    &nbsp; <mat-icon>loupe</mat-icon>&nbsp;Load PDF
                                </a>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="payrollColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: payrollColumns;"></tr>
                    </table>
                    <br>
                </mat-card-content>
            </mat-card>
        </mat-grid-tile>
    </mat-grid-list>
</div>
<div *ngIf="employee === undefined">
    employee is beeing loaded please wait... If the employee does not load try the following steps:
    <ul>
        <li>Ensure the employee exists</li>
        <li>Reload this webpage</li>
        <li>Logut and login to your xedap instance</li>
        <li>If the two options above do not work wite a bug report and contact our customer supprot at <a href="mailto:info@xedap.de">info@xedap.de</a></li>
    </ul>
</div>