import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { EmployeeService } from 'src/app/services/employee.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-edit-employee',
  templateUrl: './edit-employee.component.html',
  styleUrls: ['./edit-employee.component.scss']
})
export class EditEmployeeComponent implements OnInit {

  constructor(
    public employeeService: EmployeeService,
    public dialogRef: MatDialogRef<EditEmployeeComponent>,
    public notificationService: NotificationService
  ) { }

  ngOnInit(): void {
  }
  onClear() {
    this.employeeService.addEmployeeForm.reset();
    this.employeeService.initializeEditEmployeeForm();
    this.notificationService.success('Updated Employee Data submitted successfully');
  }

  onClose() {
    this.employeeService.addEmployeeForm.reset();
    this.employeeService.initializeEditEmployeeForm();
    this.dialogRef.close();
  }

  onEditEmployee() {
    this.employeeService.editEmployee(this.employeeService.editEmployeeForm.value);
    this.employeeService.editEmployeeForm.reset();
    this.employeeService.initializeEditEmployeeForm();
    this.notificationService.success('Data for updating employee submitted successfully');
    this.onClose();
  }

}