<h2>Create new Invoice</h2>
<form [formGroup]="invoiceForm" [hidden]="success" (ngSubmit)="submitHandler()">
    <br>
    <h3>Customer Data:</h3>
    <mat-form-field>
        <input matInput placeholder="First Name" formControlName="firstName">
    </mat-form-field>
    <br>
    <mat-form-field>
        <input matInput placeholder="Last Name" formControlName="lastName">
    </mat-form-field>
    <br>
    <mat-form-field>
        <input matInput placeholder="Street" formControlName="street">
    </mat-form-field>
    <br>
    <mat-form-field>
        <input matInput placeholder="Street Number" formControlName="streetNumber">
    </mat-form-field>
    <br>
    <mat-form-field>
        <input matInput placeholder="Additional Information" formControlName="additionalInfo">
    </mat-form-field>
    <br>
    <mat-form-field>
        <input matInput placeholder="Postal Code" formControlName="postalCode">
    </mat-form-field>
    <br>
    <mat-form-field>
        <input matInput placeholder="City" formControlName="city">
    </mat-form-field>
    <br>
    <mat-form-field>
        <input matInput placeholder="Country" formControlName="country">
    </mat-form-field>
    <br>
    <mat-form-field>
        <input matInput placeholder="Email" formControlName="email">
    </mat-form-field>
    <br>
    <mat-form-field>
        <input matInput placeholder="Telephone Number" formControlName="telephoneNumber">
    </mat-form-field>
    <br>
    <h3>Invoice Data:</h3>
    <br>
    <mat-form-field>
        <input matInput type="date" placeholder="Invoice Date" formControlName="invoiceDate">
    </mat-form-field>
    <br>
    <mat-form-field>
        <input matInput type="date" placeholder="Performance Date" formControlName="performanceDate">
    </mat-form-field>
    <br>
    <mat-form-field>
        <input matInput placeholder="Discount" formControlName="discount">
    </mat-form-field>
    <br>
    <mat-form-field>
        <input matInput placeholder="Shipping Cost" formControlName="shippingCost">
    </mat-form-field>
    <br>
    <mat-checkbox formControlName="isPaid" color="primary">Payment recieved</mat-checkbox>
    <br>
    <mat-checkbox formControlName="sendMail" color="primary">Send invoice via Email</mat-checkbox>
    <br>
    <mat-form-field>
        <input matInput placeholder="Warranty" formControlName="warranty">
    </mat-form-field>
    <br>
    <h3>Invoice Items:</h3>
    <div formArrayName="items">
        <div *ngFor="let phone of itemForms.controls; let i=index" [formGroupName]="i">
            <select  class="selectpicker dropdown mt-4 ml-3" id="type" data-live-search="true" formControlName="id">
                <option>choose article...</option>
                <option *ngFor="let product of products$ | async" [value]="product.id">{{product.description}}</option>
             </select>
            <mat-form-field>
                <input matInput placeholder="Amount" formControlName="amount" value="1">
            </mat-form-field>
            <mat-form-field>
                <input matInput placeholder="Different Price (without Tax, optional)" formControlName="diffPrice">
            </mat-form-field>
            <button mat-raised-button color="warn" (click)="deleteItem(i)">
                <fa-icon [icon]="faMinus"></fa-icon>&nbsp; Delete
            </button>

        </div>
    </div>

    <button mat-raised-button type="button" (click)="addItem()">
        <fa-icon [icon]="faPlus"></fa-icon>&nbsp; Add Invoice Item
    </button>
    <br><br>
    <button mat-raised-button color="primary" type="submit" [disabled]="invoiceForm.invalid">
        <fa-icon [icon]="faSubmit"></fa-icon>&nbsp; Submit Invoice
    </button>
</form>
<div *ngIf="success" class="notification is-success">
    Yay! We received your submission
</div>