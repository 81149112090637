import { UnpaidInvoicesComponent } from './pages/invoice/unpaid-invoices/unpaid-invoices.component';
import { IncomeStatementComponent } from './pages/stats/income-statement/income-statement.component';
import { UploadInvoiceComponent } from './pages/incoming-invoice/upload-invoice/upload-invoice.component';
import { GenerateCostEstimateComponent } from './pages/cost-estimate/generate-cost-estimate/generate-cost-estimate.component';
import { ExistingCustomerCostEstimateComponent } from './pages/cost-estimate/existing-customer-cost-estimate/existing-customer-cost-estimate.component';
import { NewCustomerCostEstimateComponent } from './pages/cost-estimate/new-customer-cost-estimate/new-customer-cost-estimate.component';
import { WoocommerceProductsComponent } from './pages/woocommerce/woocommerce-products/woocommerce-products.component';
import { WoocommerceOrdersComponent } from './pages/woocommerce/woocommerce-orders/woocommerce-orders.component';
import { EbaySalesComponent } from './pages/ebay/ebay-sales/ebay-sales.component';
import { EbayAuthComponent } from './pages/ebay/ebay-auth/ebay-auth.component';
import { UserManagerComponent } from './pages/login/user-manager/user-manager.component';
import { LoginComponent } from './pages/login/login.component';
import { GeneratePayrollComponent } from './pages/employee/generate-payroll/generate-payroll.component';
import { EmployeeDetailsComponent } from './pages/employee/employee-details/employee-details.component';
import { EmployeeComponent } from './pages/employee/employee.component';
import { IncomingInvoiceComponent } from './pages/incoming-invoice/incoming-invoice.component';
import { HomeComponent } from './pages/home/home.component';
import { GenerateContractReceiptComponent } from './pages/contract/generate-contract-receipt/generate-contract-receipt.component';
import { GenerateShippingInvoiceComponent } from './pages/invoice/generate-shipping-invoice/generate-shipping-invoice.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { ExistingCustomerInvoiceComponent } from './pages/invoice/existing-customer-invoice/existing-customer-invoice.component';
import { UpdateAddressComponent } from './pages/customer/update-address/update-address.component';
import { UpdateCustomerComponent } from './pages/customer/update-customer/update-customer.component';
import { GenerateInvoiceComponent } from './pages/invoice/generate-invoice/generate-invoice.component';
import { CustomerDetailsComponent } from './pages/customer/customer-details/customer-details.component';
import { ContractComponent } from './pages/contract/contract.component';
import { NewInvoiceComponent } from './pages/invoice/new-invoice/new-invoice.component';
import { InvoiceComponent } from './pages/invoice/invoice.component';
import { ProductUpdateFormComponent } from './pages/products/product-update-form/product-update-form.component';
import { CustomerComponent } from './pages/customer/customer.component';
import { ProductFormComponent } from './pages/products/product-form/product-form.component';
import { ProductsComponent } from './pages/products/products.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WoocommerceOrderDetailsComponent } from './pages/woocommerce/woocommerce-order-details/woocommerce-order-details.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'products', component: ProductsComponent },
  { path: 'product-form', component: ProductFormComponent },
  { path: "product-update/:id", component: ProductUpdateFormComponent },
  { path: 'customer', component: CustomerComponent },
  { path: 'update-customer/:id', component: UpdateCustomerComponent },
  { path: 'update-Address/:id', component: UpdateAddressComponent },
  { path: 'customer-details/:id', component: CustomerDetailsComponent },
  { path: 'existing-customer-invoice/:id', component: ExistingCustomerInvoiceComponent },
  { path: 'invoice', component: InvoiceComponent },
  { path: 'new-invoice', component: NewInvoiceComponent },
  { path: 'contract', component: ContractComponent },
  { path: 'generate-invoice/:id', component: GenerateInvoiceComponent },
  { path: 'generate-shipping-invoice/:id', component: GenerateShippingInvoiceComponent },
  { path: 'generate-contract-receipt/:id', component: GenerateContractReceiptComponent },
  { path: 'settings', component: SettingsComponent },
  { path: 'incoming-invoice', component: IncomingInvoiceComponent },
  { path: 'employee', component: EmployeeComponent },
  { path: 'employee-details/:id', component: EmployeeDetailsComponent },
  { path: 'generate-payroll/:id', component: GeneratePayrollComponent },
  { path: 'login', component: LoginComponent },
  { path: 'user-manager', component: UserManagerComponent },
  { path: 'ebay-auth', component: EbayAuthComponent },
  { path: 'ebay-sales', component: EbaySalesComponent },
  { path: 'woocommerce-orders', component: WoocommerceOrdersComponent },
  { path: 'woocommerce-products', component: WoocommerceProductsComponent },
  { path: 'woocommerce-order-details/:id', component: WoocommerceOrderDetailsComponent },
  { path: 'new-customer-cost-estimate', component: NewCustomerCostEstimateComponent },
  { path: 'existing-customer-cost-estimate/:id', component: ExistingCustomerCostEstimateComponent },
  { path: 'generate-cost-estimate/:id', component: GenerateCostEstimateComponent },
  { path: 'upload-invoice', component: UploadInvoiceComponent },
  { path: 'income-statement', component: IncomeStatementComponent },
  { path: 'unpaid-invoices', component: UnpaidInvoicesComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
