import { EbayService } from './../../../services/ebay.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-ebay-auth',
  templateUrl: './ebay-auth.component.html',
  styleUrls: ['./ebay-auth.component.scss']
})
export class EbayAuthComponent implements OnInit {

  code!: string;
  private router: Router;
  private ebayService: EbayService;

  constructor(private activatedRoute: ActivatedRoute, ebayService: EbayService, router: Router) {
    //get URL parameter "code" - ebay auth page will call this component with auth code in parameter
    this.activatedRoute.queryParams.subscribe(params => {
      let code = params['code'];
      console.log(code); // Print the parameter to the console. 
    });
    this.router = router;
    this.ebayService = ebayService;
  }

  ngOnInit() {
    //get dates for comparison
    let expires = new Date((localStorage.getItem('ebayTokenExpires') || '').toString());
    let now = new Date();
    //check if valid token already exists
    if (localStorage.getItem('ebayToken') != null && now.getTime() > expires.getTime()) {
      this.router.navigate(['ebay-sales'])  //If Token exists navigate to ebay sales page
    } else {
      localStorage.removeItem('ebayToken')
      localStorage.removeItem('ebayTokenExpires')
    }
    if (this.code == null) {  //If no code in url navigate to ebay auth page - this will call this component with auth code in parameter
      console.log("no code found - getting auth URI")
      this.ebayService.navigateToAuthUrl();
    } else {  //if auth code is in url parameters generate ebay token from auth code
      this.ebayService.getToken(this.code); //this will generate token and store token in browser storage
      this.router.navigate(['ebay-sales']) //after token is generated we can navigate to ebay sales page and call the ebay api
    }
  }
}
