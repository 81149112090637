import { LoginService } from './login.service';
import { fullContract } from './../models/fullContract.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Contract } from '../models/contract.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContractService {

  contracts: Contract[] = [];
  contract!: Contract;

  constructor(public httpClient: HttpClient, public loginService: LoginService) { }

  form: FormGroup = new FormGroup({
    id: new FormControl(),
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    street: new FormControl('', Validators.required),
    streetNumber: new FormControl('', Validators.required),
    postalCode: new FormControl('', Validators.required),
    city: new FormControl('', Validators.required),
    additionalInfo: new FormControl(''),
    country: new FormControl('Deutschland', Validators.required),
    email: new FormControl(''),
    telephoneNumber: new FormControl(''),
    manufacturer: new FormControl(''),
    model: new FormControl(''),
    imei: new FormControl(''),
    information: new FormControl('')
  });

  existingCustomerForm: FormGroup = new FormGroup({
    id: new FormControl(),
    customer: new FormControl(),
    manufacturer: new FormControl(''),
    model: new FormControl(''),
    imei: new FormControl(''),
    information: new FormControl('')
  });

  editContractForm: FormGroup = new FormGroup({
    id: new FormControl(),
    status: new FormControl('', Validators.required),
    trackingStatus: new FormControl('', Validators.required)
  });

  getContracts() {
    return this.httpClient.get<any>(this.loginService.getBackendUrl() + '/contract', { headers: this.loginService.getAuthHeader() });
  }

  getRecentContracts() {
    return this.httpClient.get<any>(this.loginService.getBackendUrl() + '/contract/recent', { headers: this.loginService.getAuthHeader() });
  }

  getContract(id: number) {
    return this.httpClient.get<fullContract>(this.loginService.getBackendUrl() + '/contract/' + id, { headers: this.loginService.getAuthHeader() });
  }

  addContract(formData: any) {
    console.log(formData)
    this.httpClient.post(this.loginService.getBackendUrl() + '/contract/newCustomerContract', formData, { headers: this.loginService.getAuthHeader() }).subscribe(
      (result) => {
        console.log("result", result)
        window.location.reload()
      }
    );
  }

  addExistingCustomerContract(formData: any) {
    console.log(formData)
    this.httpClient.post(this.loginService.getBackendUrl() + '/contract/existingCustomerContract', formData, { headers: this.loginService.getAuthHeader() }).subscribe(
      (result) => {
        console.log("result", result)
        window.location.reload()
      }
    );
  }

  editContract(formData: any) {
    console.log(formData)
    this.httpClient.post(this.loginService.getBackendUrl() + '/contract/editContract', formData, { headers: this.loginService.getAuthHeader() }).subscribe(
      (result) => {
        console.log("result", result)
        window.location.reload()
      }
    );
  }

  finishContract(id: number) { //wäre vielleicht noch nicer mit post
    this.httpClient.get(this.loginService.getBackendUrl() + '/contract/finish/' + id, { headers: this.loginService.getAuthHeader() }).subscribe(
      (result) => {
        console.log("result", result)
        window.location.reload()
      }
    );
  }

  initializeFormGroup() {
    this.form.setValue({
      id: 'none',
      firstName: '',
      lastName: '',
      street: '',
      streetNumber: '',
      postalCode: '',
      additionalInfo: '',
      city: '',
      country: 'Deutschland',
      email: '',
      telephoneNumber: '',
      manufacturer: '',
      model: '',
      imei: '',
      information: ''
    });
  }
  initializeExistingCustomerForm(customer: number) {
    this.existingCustomerForm.setValue({
      id: 'none',
      customer: customer,
      manufacturer: '',
      model: '',
      imei: '',
      information: ''
    });
  }

  initializeEditContractForm() {
    this.existingCustomerForm.setValue({
      id: 'none',
      status: '',
      trackingStatus: ''
    });
  }

  populateEditContractForm(id: number, status: string) {
    console.warn(id)
    this.editContractForm.patchValue({
      id: id,
      status: status
    });
  }
}
