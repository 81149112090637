<br>
<a href="upload-invoice" class="button">
    <mat-icon>add</mat-icon>&nbsp; Add new Invoice
</a>
<br>
<br>
<h2>Incoming Invoices</h2>
<i class="fas fa-file-contract"></i>
<table mat-table [dataSource]="IncomingInvoiceService.incomingInvoices" class="mat-elevation-z8">

    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

    <!-- Date Column -->
    <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef> Date </th>
        <td mat-cell *matCellDef="let invoice"> {{dateService.formatDate(invoice.invoiceDate)}} &nbsp;&nbsp;</td>
    </ng-container>

    <!-- Info Column -->
    <ng-container matColumnDef="info">
        <th mat-header-cell *matHeaderCellDef> Info </th>
        <td mat-cell *matCellDef="let invoice"> {{invoice.info}} &nbsp;</td>
    </ng-container>

    <!-- Amount Column -->
    <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef> Amount </th>
        <td mat-cell *matCellDef="let invoice"> {{invoice.amount}}€ &nbsp;&nbsp;</td>
    </ng-container>

    <!-- Tax Column -->
    <ng-container matColumnDef="tax">
        <th mat-header-cell *matHeaderCellDef> Tax </th>
        <td mat-cell *matCellDef="let invoice"> {{invoice.tax}}€ &nbsp;</td>
    </ng-container>

    <!-- Load File Column -->
    <ng-container matColumnDef="file">
        <th mat-header-cell *matHeaderCellDef> File </th>
        <td mat-cell *matCellDef="let invoice">
            <a href="https://mobilofix.de/intern/geschaeftsfuehrung/{{invoice.file}}" mat-raised-button
                color="primary">&nbsp; <mat-icon>description</mat-icon> Invoice File</a>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>