<mat-tab-group>
    <mat-tab label="Admin" *ngIf="authService.checkAdmin()">
        <div>
            <h1>Admin Settings</h1>
            <button mat-raised-button (click)="openAddNewUser()">
                <mat-icon>add</mat-icon>&nbsp; Add new user
            </button>
            <br>
            <br>
            <a href="user-manager" class="mat-raised-button">
                <mat-icon>settings</mat-icon>&nbsp; Manage Users
            </a>
            <br>
            <br>
            <button mat-raised-button (click)="backupDatabase()">
                <mat-icon>backup</mat-icon>&nbsp; Create database backup
            </button>
            <br>
            <br>
        </div>
    </mat-tab>
    <mat-tab label="Apperance">
        <h2>Dark Mode</h2>
        <br>
        <mat-slide-toggle [(ngModel)]="nav.isDarkTheme" (change)="nav.storeThemeSelection()">
            <mat-icon>bedtime</mat-icon>Dark Mode
        </mat-slide-toggle>
        <br>
        <br>
        <h2>Update Company Logo:</h2>
        <mat-icon
            matTooltip="Logo image file need to be in formats like png, jpg or jpeg. As aspect ratio we recommend 5:1. The recommended resolution is 5000px x 1000px">
            info
        </mat-icon>
        <form [formGroup]="settingsService.logoForm" (ngSubmit)="onSubmitNewLogo()">
            <mat-form-field>
                <input matInput placeholder="Select image file" formControlName="logo">
            </mat-form-field>
            <br>
            <button mat-raised-button color="primary" type="submit">
                <mat-icon>update</mat-icon>&nbsp; Update Company Logo (not working yet)
            </button>
            <br>
            <br>
        </form>

    </mat-tab>
    <mat-tab label="API connections">
        <h2>Woocommerce Settings:</h2>
        <form [formGroup]="settingsService.woocommerceForm" (ngSubmit)="onSubmitWoocommerce()">
            <mat-form-field>
                <input matInput placeholder="Woocommerce API Url" formControlName="url">
            </mat-form-field>
            <br>
            <mat-form-field>
                <input matInput placeholder="Woocommerce Client Key" formControlName="clientKey">
            </mat-form-field>
            <br>
            <mat-form-field>
                <input matInput type="password" placeholder="Woocommerce Client Secret" formControlName="clientSecret">
            </mat-form-field>
            <br>
            <button mat-raised-button color="primary" type="submit"
                [disabled]="settingsService.woocommerceForm.invalid">
                <mat-icon>send</mat-icon>&nbsp; Set Woocommerce API keys
            </button>
        </form>

        <br>
        <br>
        <h2>Telephone Settings:</h2>
        <form [formGroup]="settingsService.placetelForm" (ngSubmit)="onSubmitPlacetel()">
            <mat-form-field>
                <input matInput placeholder="Placetel API Key" formControlName="key">
            </mat-form-field>
            <br>

            <button mat-raised-button color="primary" type="submit" [disabled]="settingsService.placetelForm.invalid">
                <mat-icon>send</mat-icon>&nbsp; Set Placetel Api Key
            </button>
            <br>
            <br>
        </form>
    </mat-tab>
    <mat-tab label="Backup Downloader">
        <h2>Download Invoices:</h2>
        <h3>From: &nbsp;
            <input #start type="date" multiple (change)="downloadInvoices(start.value, end.value)">
            To: &nbsp;
            <input #end type="date" multiple (change)="downloadInvoices(start.value, end.value)">
        </h3>
        <small>DO NOT CLOSE THIS PAGE UNTIL DOWNLOAD IS COMPLETED! Download will start automatically as new window when start and end date are entered. This may take some time depending on date range and number of files. If your downloaded Zip File is empty there are no invoies in the date range</small>
        <br>
        <br>
        <h2>Download Expense Reports:</h2>
        <h3>From: &nbsp;
            <input #start type="date" multiple (change)="downloadExpenseReports(start.value, end.value)">
            To: &nbsp;
            <input #end type="date" multiple (change)="downloadExpenseReports(start.value, end.value)">
        </h3>
        <small>DO NOT CLOSE THIS PAGE UNTIL DOWNLOAD IS COMPLETED! Download will start automatically as new window when start and end date are entered. This may take some time depending on date range and number of files. If your downloaded Zip File is empty there are no expense reports in the date range</small>
    </mat-tab>
</mat-tab-group>