<mat-toolbar>
    <span>Confirm OCR Predictions</span>
    <span class="fill-remaining-space"></span>
    <button class="btn-dialog-close" mat-stroked-button (click)="onClose()" tabIndex="-1">
        <mat-icon>clear</mat-icon>
    </button>
</mat-toolbar>
<mat-dialog-content>
    <form [formGroup]="incomingInvoiceService.confirmInvoiceForm" (ngSubmit)="onSubmit()">
        <input type="hidden" formControlName="filename">
        <mat-form-field>
            <input matInput placeholder="Invoice Nr" formControlName="invoiceNr">
        </mat-form-field>
        <br>
        <mat-form-field>
            <input matInput type="date" placeholder="Invoice Date" formControlName="date">
        </mat-form-field>
        <br>
        <mat-form-field>
            <input matInput placeholder="Amount" formControlName="amount">
        </mat-form-field>
        <br>
        <mat-form-field>
            <input matInput placeholder="Tax" formControlName="tax">
        </mat-form-field>
        <br>
        <mat-form-field>
            <input matInput placeholder="Annotation" formControlName="annotation">
        </mat-form-field>
        <br>

        <button mat-raised-button color="primary" type="submit">
            <mat-icon>send</mat-icon>&nbsp; Submit data
        </button>

    </form>

</mat-dialog-content>