import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from 'src/app/services/notification.service';
import { CustomerService } from './../../../services/customer.service';

@Component({
  selector: 'app-update-customer',
  templateUrl: './update-customer.component.html',
  styleUrls: ['./update-customer.component.scss']
})
export class UpdateCustomerComponent implements OnInit {

  constructor(
    public CustomerService: CustomerService,
    public dialogRef: MatDialogRef<UpdateCustomerComponent>,
    public notificationService: NotificationService,
  ) { }

  ngOnInit(): void {
  }

  onClear() {
    this.CustomerService.updateForm.reset();
    this.CustomerService.initializeFormGroup();
    this.notificationService.success('Customer Update data submitted successfully');
  }

  onClose() {
    this.CustomerService.updateForm.reset();
    this.CustomerService.initializeFormGroup();
    this.dialogRef.close();
  }

  onSubmit() {
    this.CustomerService.updateCustomer(this.CustomerService.updateForm.value);
    this.CustomerService.updateForm.reset();
    this.CustomerService.initializeFormGroup();
    this.notificationService.success('Edited customer data submitted successfully');
    this.onClose();
  }

}
