import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { LoginService } from 'src/app/services/login.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-edit-account',
  templateUrl: './edit-account.component.html',
  styleUrls: ['./edit-account.component.scss']
})
export class EditAccountComponent implements OnInit {

  constructor(public loginService: LoginService, public dialogRef: MatDialogRef<EditAccountComponent>, public notificationService: NotificationService) { }

  ngOnInit(): void {
  }

  onClear() {
    this.loginService.changePasswordForm.reset();
    this.loginService.initializeChangePasswordForm();
    this.notificationService.success('Login Data submitted successfully');
  }

  onClose() {
    this.loginService.changePasswordForm.reset();
    this.loginService.initializeChangePasswordForm();
    this.dialogRef.close();
  }

  onChangePassword() {
    this.loginService.changePassword(this.loginService.changePasswordForm.value);
    this.loginService.changePasswordForm.reset();
    this.loginService.initializeChangePasswordForm();
    this.notificationService.success('Login data submitted successfully');
    this.onClose();
  }

}
