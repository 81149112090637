import { LoginService } from './login.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Product } from '../models/product.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  products: Product[] = [];
  product!: Product;

  constructor(public httpClient: HttpClient, public loginService: LoginService) { }

  postProduct(formData: Product) {
    console.log(formData)
    this.httpClient.post(this.loginService.getBackendUrl() + '/product', formData, { headers: this.loginService.getAuthHeader() }).subscribe(
      (result) => {
        console.log("result", result)
      }
    );
  }

  putProduct(id: string, formData: Product) {
    console.log(formData)
    this.httpClient.put(this.loginService.getBackendUrl() + '/product/' + id, formData, { headers: this.loginService.getAuthHeader() }).subscribe(
      (result) => {
        console.log("result", result)
      }
    );
  }

  getProducts() {
    this.httpClient.get<any>(this.loginService.getBackendUrl() + '/product', { headers: this.loginService.getAuthHeader() }).subscribe(
      response => {
        console.log(response);
        this.products = response;
      }
    );
  }

  getSelectProducts(): Observable<Product[]> {
    return this.httpClient.get<Product[]>(this.loginService.getBackendUrl() + '/product', { headers: this.loginService.getAuthHeader() });
  }

  getProduct(id: string) {
    return this.httpClient.get<Product>(this.loginService.getBackendUrl() + '/product/' + id, { headers: this.loginService.getAuthHeader() });
  }
}
