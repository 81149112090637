<br>
<button mat-raised-button printSectionId="print-section" ngxPrint>print</button>&nbsp;
<html>
<div *ngIf="contract !== undefined">
    <div id="print-section">

        <head>
            <meta charset="utf-8" />
            <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta2/dist/css/bootstrap.min.css" rel="stylesheet"
                integrity="sha384-BmbxuPwQa2lc/FVzBcNJ7UAyJxM6wuqIj61tLrc4wSX0szH/Ev+nYRRuWlolflfl"
                crossorigin="anonymous">
            <!-- A4 CSS -->
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/paper-css/0.3.0/paper.css">

            <title>Contract {{contract.contract.iD}}</title>
        </head>

        <!-- Set "A5", "A4" or "A3" for class name -->
        <!-- Set also "landscape" if you need -->

        <body class="A4">


            <!-- Each sheet element should have the class "sheet" -->
            <!-- "padding-**mm" is optional: you can set 10, 15, 20 or 25 -->
            <section class="sheet padding-15mm">

                <!-- Write HTML just like a web page -->
                <div class="col-12">
                    <div class="row">
                        <div class="col-md-6">
                            <img src="/assets/logo.png" width="300">
                        </div>
                        <div class="col-md-6 text-end">
                            <p class="font-weight-bold mb-1">{{contract.config.company}}</p>
                            <p class="text-muted">
                                <small>
                                    {{contract.config.street}}<br>
                                    {{contract.config.postalCode}} {{contract.config.city}}<br>
                                    Tel.: {{contract.config.telephone}}<br>
                                    E-Mail: {{contract.config.email}}<br>
                                    Homepage: {{contract.config.website}}<br>
                                    SteuerNr.: {{contract.config.taxNumber}}<br>
                                    Finanzamt: {{contract.config.taxOffice}}<br>
                                    Kontoinhaber: {{contract.config.bankAccountOwner}}<br>
                                    IBAN: {{contract.config.iban}}
                                </small>
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <p>
                                <small>
                                    {{contract.customer.firstName}} {{contract.customer.lastName}}<br>
                                    {{contract.customer.address.street}} {{contract.customer.address.streetNumber}}<br>
                                    {{contract.customer.address.postalCode}} {{contract.customer.address.city}}<br>
                                    {{contract.customer.address.country}}<br>
                                </small>
                            </p>
                        </div>
                        <div class="col-md-6 text-end">
                            <p>
                                <small>
                                    <br>
                                    Datum: {{getDate(contract.contract.date)}} <br>
                                </small>
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <h2>Device:</h2>
                            Manufacturer: {{contract.contract.device.manufacturer}}<br>
                            Model: {{contract.contract.device.model}}<br>
                            IMEI: {{contract.contract.device.imei}}<br>
                            Defect: {{contract.contract.device.defect}}<br>
                        </div>


                    </div>
                    <br>
                    <div class="row">
                        <div class="col">
                            <p><small>Wir danken für Ihren Auftrag</small></p>
                            <p class="text-muted">
                                <small>
                                    Scannen Sie diesen Code um den Status Ihres Auftrages Online zu verfolgen:

                                    <img id='barcode'
                                        src="https://api.qrserver.com/v1/create-qr-code/?data=https://mobilofix.de/auftragVerfolgen/anzeige?nr={{contract.contract.token}}&amp;size=100x100"
                                        alt="" title="Tracking status" width="100" height="100" /><br>
                                    Alternativ können Sie folgenden Link nutzen: https://mobilofix.de/auftragVerfolgen
                                    <br>
                                    Ihre Verfolgungsnumemr lautet: {{contract.contract.token}}
                                </small>
                            </p>
                        </div>
                    </div>
                </div>

            </section>
            <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta2/dist/js/bootstrap.bundle.min.js"
                integrity="sha384-b5kHyXgcpbZJO/tY9Ul7kGkf1S0CWuKcCD38l8YkeH8z8QjE0GmW1gYU5S9FOnJ0"
                crossorigin="anonymous"></script>

        </body>

    </div>
</div>
<div *ngIf="contract === undefined">
    contract is beeing loaded please wait... If the contract does not load try the following steps: 
    <li>
        <ul>Ensure the contract exists</ul>
        <ul>Reload the website</ul>
        <ul>If the two options above do not work wite a bug report and contact our customer supprot at <a href= "mailto:info@xedap.de">info@xedap.de</a></ul>
    </li>
</div>

</html>