import { Invoice } from './../../../models/invoice.model';
import { InvoiceService } from './../../../services/invoice.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { fullInvoice } from 'src/app/models/fullInvoice.model';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-generate-invoice',
  templateUrl: './generate-shipping-invoice.component.html',
  styleUrls: ['./generate-shipping-invoice.component.scss']
})
export class GenerateShippingInvoiceComponent implements OnInit {

  invoice!: fullInvoice;
  id!: any;

  constructor(public InvoiceService: InvoiceService, private route: ActivatedRoute, private router: Router) { }
  printPage() {
    window.print();
  }
  
  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get("id")
    console.log(this.id);
    this.InvoiceService.getInvoice(this.id).subscribe(
      response => {
        console.log(response);
        this.invoice = response;
      }
    )
  }
  getDate(d: string): any {
    const datepipe: DatePipe = new DatePipe("de-DE")
    let formattedDate = datepipe.transform(d, 'dd.MM.YYYY');
    console.log(formattedDate)
    return formattedDate
  }

}
