<p>customer works!</p>
<button mat-raised-button (click)="onCreate()">
    <mat-icon>add</mat-icon>&nbsp; Create
</button>
<br>
<h3>Search Customer</h3>
<mat-form-field appearance="fill">
    <mat-label>Name</mat-label>
    <input matInput type="text" (keyup)="searchCustomer($event)" placeholder="Find" />
</mat-form-field>
<br><br>

<table mat-table [dataSource]="customers" class="mat-elevation-z8">

    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

    <!-- ID Column -->
    <ng-container matColumnDef="iD">
        <th mat-header-cell *matHeaderCellDef> ID </th>
        <td mat-cell *matCellDef="let customer"> {{customer.id}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let customer"> {{customer.firstName}} {{customer.lastName}} </td>
    </ng-container>

    <!-- Address Column -->
    <ng-container matColumnDef="address">
        <th mat-header-cell *matHeaderCellDef> Address </th>
        <td mat-cell *matCellDef="let customer">
            {{customer.address.street}}
            {{customer.address.streetNumber}},
            {{customer.address.postalCode}}
            {{customer.address.city}}
        </td>

    </ng-container>

    <!-- Action Column -->
    <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef> Action </th>
        <td mat-cell *matCellDef="let customer">
            <a href="/customer-details/{{customer.id}}" mat-raised-button color="primary">
                <fa-icon [icon]="faDetails"></fa-icon>&nbsp; Details
            </a>&nbsp;
            <button mat-raised-button (click)="onCreateContract(customer.id)">
                <mat-icon>add</mat-icon>&nbsp; Create Contract
            </button>

        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>