<div *ngIf="statsService.incomeStatement !== undefined">
    <br>
    <mat-card class="incomeStatement-card">
        <mat-card-header>
            <div mat-card-avatar>icon here</div>
            <mat-card-title>Income Statement</mat-card-title>
            <mat-card-subtitle>Calculate your income statement for given date range</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <h3>Income Statement from: &nbsp;
                <input #start type="date" multiple (change)="getIncomeStatement(start.value, end.value)">
                To: &nbsp;
                <input #end type="date" multiple (change)="getIncomeStatement(start.value, end.value)">
            </h3>
            <br>
            <br>
            Income: &nbsp;{{this.statsService.incomeStatement.income}}€ <br>
            Total Spendings: &nbsp;{{this.statsService.incomeStatement.spendings +
            this.statsService.incomeStatement.workerCost}}€ <br>
            &nbsp;<small>Worker Cost: {{this.statsService.incomeStatement.workerCost}}€</small><br>
            &nbsp;<small>Normal Spendings: {{this.statsService.incomeStatement.spendings}}€</small><br>
            <b>Profit: &nbsp;{{this.statsService.incomeStatement.profit}}€</b> <br>
            VAT: &nbsp;{{this.statsService.incomeStatement.vat}}€ <br>
            Input Tax: &nbsp;{{this.statsService.incomeStatement.inputTax}}€ <br>
            Tax to be payed: &nbsp;{{this.statsService.incomeStatement.taxToBePayed}}€ <br>
        </mat-card-content>
        <mat-card-actions>
        </mat-card-actions>
    </mat-card>
</div>
<div *ngIf="statsService.incomeStatement === undefined">
    income statement is beeing loaded please wait... If the income statement does not load try the following steps:
    <ul>
        <li>Ensure the date range is correct</li>
        <li>Reload this webpage</li>
        <li>Logut and login to your xedap instance</li>
        <li>If the two options above do not work wite a bug report and contact our customer supprot at <a
                href="mailto:info@xedap.de">info@xedap.de</a></li>
    </ul>
</div>