<mat-toolbar>
    <span>Add new User</span>
</mat-toolbar>
<small>Password will be send via email (should be changed after first login)</small>
<mat-dialog-content>
    <form [formGroup]="loginService.addNewUserForm" (ngSubmit)="onAddNewUser()">
        <br>
        <mat-form-field>
            <input matInput placeholder="Username" formControlName="username">
        </mat-form-field>
        <br>
        <mat-form-field>
            <input matInput placeholder="Email" formControlName="email">
        </mat-form-field>
        <br>
        <mat-checkbox formControlName="admin" color="primary">Admin</mat-checkbox>
        <br>
        <br>
        <button mat-raised-button color="primary" type="submit" [disabled]="loginService.addNewUserForm.invalid">
            <mat-icon>send</mat-icon>&nbsp; Add new User
        </button>
    </form>
</mat-dialog-content>