<mat-toolbar>
    <span>Edit Contract</span>
    <span class="fill-remaining-space"></span>
    <button class="btn-dialog-close" mat-stroked-button (click)="onClose()" tabIndex="-1">
        <mat-icon>clear</mat-icon>
    </button>
</mat-toolbar>
<mat-dialog-content>
    <form [formGroup]="contractService.editContractForm" (ngSubmit)="onSubmit()">
        <br>
        <input type="hidden" formControlName="id">
        <mat-form-field>
            <input matInput placeholder="Status" formControlName="status">
        </mat-form-field>
        <br>
        <select class="custom-select" formControlName="trackingStatus">
            <option *ngFor="let tracking of trackingStates" [ngValue]="tracking">{{tracking}}</option>
        </select>
        <br>
        <br>

        <button mat-raised-button color="primary" type="submit" [disabled]="contractService.editContractForm.invalid">
            <mat-icon>send</mat-icon>&nbsp; Submit Changes
        </button>
    </form>
</mat-dialog-content>