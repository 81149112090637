import { InvoiceService } from './../../../services/invoice.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { HtmlInvoice } from 'src/app/models/htmlInvoice.model';

@Component({
  selector: 'app-generate-invoice',
  templateUrl: './generate-invoice.component.html',
  styleUrls: ['./generate-invoice.component.scss']
})
export class GenerateInvoiceComponent implements OnInit {

  htmlInvoice$!: HtmlInvoice;
  id!: any;

  constructor(public InvoiceService: InvoiceService, private route: ActivatedRoute, private router: Router) { }
  printPage() {
    window.print();
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get("id")
    console.log(this.id);
    this.InvoiceService.getInvoiceHtml(this.id).subscribe(
      response => {
        console.log(response);
        this.htmlInvoice$ = response;
      }
    )
  }
  getDate(d: string): any {
    const datepipe: DatePipe = new DatePipe("de-DE")
    let formattedDate = datepipe.transform(d, 'dd.MM.YYYY');
    console.log(formattedDate)
    return formattedDate
  }
  createReturnInvoice(): any {
    if (confirm("Are you sure to return the invoice "+this.htmlInvoice$.invoiceID+"? This will create an new return 'invoice'. You can only return an invoice once. You can NOT revert this action!")) {
      this.InvoiceService.createReturnInvoice(this.htmlInvoice$.invoiceID).subscribe(
        response => {
          console.log("return invoice response: " + response);
          this.router.navigate(['/generate-invoice/'+response.toString()]);
        }
      );
    }
  }

  sendInvoiceEmail(){
    if (confirm("Are you sure to send the invoice "+this.htmlInvoice$.invoiceID+" as PDF copy to the customer?")) {
    console.log("Sending Invoice via email")
    this.InvoiceService.sendInvoiceEmail(this.htmlInvoice$.invoiceID)
    }
  }

}
