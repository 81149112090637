import { Observable } from 'rxjs';
import { ConfirmIncomingInvoiceComponent } from './../confirm-incoming-invoice/confirm-incoming-invoice.component';
import { ocrResponse } from './../../../models/ocrResponse.model';
import { IncomingInvoiceComponent } from './../incoming-invoice.component';
import { IncomingInvoiceService } from './../../../services/incoming-invoice.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from 'src/app/services/notification.service';
import { HttpRequest } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-upload-invoice',
  templateUrl: './upload-invoice.component.html',
  styleUrls: ['./upload-invoice.component.scss']
})
export class UploadInvoiceComponent implements OnInit {
  
  readonly rootURL = environment.apiUrl;

  constructor(public incomingInvoiceService: IncomingInvoiceService, 
    public notificationService: NotificationService,
    private dialog: MatDialog
    ) { }

  ngOnInit(): void {
  }

  upload(files: any) {
    console.log("uploading file")
    if(files.length ===0){
      console.log("no file selected")
      return
    }
    const formData = new FormData();
    for(let file of files){
      console.log(file.name)
      formData.append(file.name, file)
    }
    console.log("uploading data")
    this.incomingInvoiceService.uploadInvoice(formData);
    //how to wait for data?
    

    console.log("ocr: "+this.incomingInvoiceService.ocr)
    
  }

  openConfirmForm(){
    console.log("ocr: "+this.incomingInvoiceService.ocr)
    this.incomingInvoiceService.populateConfirmForm(this.incomingInvoiceService.ocr);
    this.dialog.open(ConfirmIncomingInvoiceComponent);
  }

}
