import { HttpClient } from '@angular/common/http';
import { LoginService } from './login.service';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(private loginService: LoginService, private httpClient: HttpClient) { }

  superSearch(query: string) {
    return this.httpClient.get<any>(this.loginService.getBackendUrl() + '/search/' + query, { headers: this.loginService.getAuthHeader() });
  }
}
