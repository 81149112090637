<div *ngIf="payroll !== undefined">
    <br>
    <button mat-raised-button printSectionId="print-section" ngxPrint>print</button>&nbsp;
    <html>
    <div id="print-section">

        <head>
            <meta charset="utf-8" />
            <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta2/dist/css/bootstrap.min.css" rel="stylesheet"
                integrity="sha384-BmbxuPwQa2lc/FVzBcNJ7UAyJxM6wuqIj61tLrc4wSX0szH/Ev+nYRRuWlolflfl"
                crossorigin="anonymous">
            <!-- A4 CSS -->
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/paper-css/0.3.0/paper.css">

            <title>Payroll</title>
        </head>

        <!-- Set "A5", "A4" or "A3" for class name -->
        <!-- Set also "landscape" if you need -->

        <body class="A4">

            <!-- Each sheet element should have the class "sheet" -->
            <!-- "padding-**mm" is optional: you can set 10, 15, 20 or 25 -->
            <!-- page 1 for employee -->
            <section class="sheet padding-15mm">

                <!-- Write HTML just like a web page -->
                <div class="col-12">
                    <div class="row">
                        <div class="col-md-6">
                            <img src="/assets/logo.png" width="300">
                        </div>
                        <div class="col-md-6 text-end">
                            <p class="font-weight-bold mb-1">{{payroll.config.company}}</p>
                            <p class="text-muted">
                                <small>
                                    {{payroll.config.street}}<br>
                                    {{payroll.config.postalCode}} {{payroll.config.city}}<br>
                                    Tel.: {{payroll.config.telephone}}<br>
                                    E-Mail: {{payroll.config.email}}<br>
                                    Homepage: {{payroll.config.website}}<br>
                                    SteuerNr.: {{payroll.config.taxNumber}}<br>
                                    Finanzamt: {{payroll.config.taxOffice}}<br>
                                    Kontoinhaber: {{payroll.config.bankAccountOwner}}<br>
                                    IBAN: {{payroll.config.iban}}
                                </small>
                            </p>
                        </div>
                    </div>
                    <h2>Payroll</h2>
                    <h3>Billing Period: {{payroll.payroll.month}}</h3>
                    <br>
                    <div class="row">
                        <div class="col-md-6">
                            <p>
                                <small>
                                    {{payroll.employee.firstName}} {{payroll.employee.lastName}}<br>
                                    {{payroll.employee.street}} {{payroll.employee.streetNumber}}<br>
                                    {{payroll.employee.postalCode}} {{payroll.employee.city}}<br>
                                </small>
                            </p>
                        </div>
                        <div class="col-md-6 text-end">
                            <p>
                                <small>
                                    <br>
                                    <br>
                                    <br>
                                    <br>
                                    Date: {{dateService.formatDate(payroll.payroll.date)}} <br>
                                </small>
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col">Description</th>
                                    <th scope="col">Loan</th>
                                    <th scope="col">Hours</th>
                                    <th scope="col" class="text-end">Sum Gross</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Gehalt</td>
                                    <td>{{payroll.payroll.loan}}</td>
                                    <td>{{payroll.payroll.hours}}</td>
                                    <td class="text-end">{{payroll.payroll.sumGross}}€</td>
                                </tr>
                            </tbody>
                        </table>
                        <h4>Resulting payout amount: <b>{{payroll.payroll.sumGross}}€</b></h4>
                        <p>The payout amount wille be transferred to the following bank account:</p>
                        <small>
                            {{payroll.employee.bankAccountOwner}}<br>
                            {{payroll.employee.bank}}<br>
                            IBAN: {{payroll.employee.iban}}
                        </small>
                    </div>
                    <br>
                    <div class="row">

                    </div>
                </div>

            </section>

            <!-- page 2 for company -->
            <section class="sheet padding-15mm">

                <!-- Write HTML just like a web page -->
                <div class="col-12">
                    <div class="row">
                        <div class="col-md-6">
                            <img src="/assets/logo.png" width="300">
                        </div>
                        <div class="col-md-6 text-end">
                            <p class="font-weight-bold mb-1">{{payroll.config.company}}</p>
                            <p class="text-muted">
                                <small>
                                    {{payroll.config.street}}<br>
                                    {{payroll.config.postalCode}} {{payroll.config.city}}<br>
                                    Tel.: {{payroll.config.telephone}}<br>
                                    E-Mail: {{payroll.config.email}}<br>
                                    Homepage: {{payroll.config.website}}<br>
                                    SteuerNr.: {{payroll.config.taxNumber}}<br>
                                    Finanzamt: {{payroll.config.taxOffice}}<br>
                                    Kontoinhaber: {{payroll.config.bankAccountOwner}}<br>
                                    IBAN: {{payroll.config.iban}}
                                </small>
                            </p>
                        </div>
                    </div>
                    <h2>Lohnabrechnung (Arbeitgeber)</h2>
                    <h3>Abrechnungszeitraum: {{payroll.payroll.month}}</h3>
                    <br>
                    <div class="row">
                        <div class="col-md-6">
                            <p>
                                <small>
                                    {{payroll.employee.firstName}} {{payroll.employee.lastName}}<br>
                                    {{payroll.employee.street}} {{payroll.employee.streetNumber}}<br>
                                    {{payroll.employee.postalCode}} {{payroll.employee.city}}<br>
                                </small>
                            </p>
                        </div>
                        <div class="col-md-6 text-end">
                            <p>
                                <small>
                                    <br>
                                    <br>
                                    <br>
                                    <br>
                                    Datum: {{dateService.formatDate(payroll.payroll.date)}} <br>
                                </small>
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col">Beschreibung</th>
                                    <th scope="col">%-Satz</th>
                                    <th scope="col">Betrag</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Gehalt Brutto</td>
                                    <td> </td>
                                    <td>{{payroll.payroll.sumGross}}€</td>
                                </tr>
                                <tr>
                                    <td>Pauschale Lohnsteuer</td>
                                    <td>2%</td>
                                    <td>{{payroll.payroll.ls}}€</td>
                                </tr>
                                <tr>
                                    <td>Pauschalbeitrag Krankenversicherung</td>
                                    <td>13%</td>
                                    <td>{{payroll.payroll.kv}}€</td>
                                </tr>
                                <tr>
                                    <td>Pauschalbeitrag Rentenversicherung</td>
                                    <td>15%</td>
                                    <td>{{payroll.payroll.rv}}€</td>
                                </tr>
                                <tr>
                                    <td>U1</td>
                                    <td>0,9%</td>
                                    <td>{{payroll.payroll.u1}}€</td>
                                </tr>
                                <tr>
                                    <td>U2</td>
                                    <td>0,19%</td>
                                    <td>{{payroll.payroll.u2}}€</td>
                                </tr>
                                <tr>
                                    <td>Insolvenzgeldumlage</td>
                                    <td>0,06%</td>
                                    <td>{{payroll.payroll.inso}}€</td>
                                </tr>
                            </tbody>
                        </table>
                        <small>Summe der Abgaben: {{payroll.payroll.sumNet}}</small>
                        <h4>Gesamtaufwand: <b>{{payroll.payroll.sumExpenditure}}€</b></h4>
                    </div>
                    <br>
                </div>

            </section>

            <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta2/dist/js/bootstrap.bundle.min.js"
                integrity="sha384-b5kHyXgcpbZJO/tY9Ul7kGkf1S0CWuKcCD38l8YkeH8z8QjE0GmW1gYU5S9FOnJ0"
                crossorigin="anonymous"></script>

        </body>

    </div>

    </html>
</div>
<div *ngIf="payroll === undefined">
    payroll is beeing loaded please wait... If the payroll does not load try the following steps:
    <ul>
        <li>Ensure the payroll exists</li>
        <li>Reload this webpage</li>
        <li>Logut and login to your xedap instance</li>
        <li>If the two options above do not work wite a bug report and contact our customer supprot at <a
                href="mailto:info@xedap.de">info@xedap.de</a></li>
    </ul>
</div>