
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CostEstimateService } from 'src/app/services/cost-estimate.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ProductService } from 'src/app/services/product.service';

@Component({
  selector: 'app-existing-customer-cost-estimate',
  templateUrl: './existing-customer-cost-estimate.component.html',
  styleUrls: ['./existing-customer-cost-estimate.component.scss']
})
export class ExistingCustomerCostEstimateComponent implements OnInit {
  existingCustomerCostEstimateForm!: FormGroup;
  products$: any;
  id!: any;

  // Form state
  loading = false;
  success = false;

  constructor(
    private route: ActivatedRoute,
    public costEstimateService: CostEstimateService, 
    public productService: ProductService,
    public notificationService: NotificationService,
    private fb:FormBuilder,
    ) { }

    ngOnInit(): void {
      this.id = this.route.snapshot.paramMap.get("id")
      this.existingCustomerCostEstimateForm = this.fb.group({
        customer: this.id,
        items: this.fb.array([])
      })
  
      this.products$ = this.getSelectProduct();
  
      
    }
    get itemForms() {
      return this.existingCustomerCostEstimateForm.get('items') as FormArray
    }
    
    addItem() {
      const item = this.fb.group({ 
        id: [],
        amount: []
      })
    
      this.itemForms.push(item);
    }
    
    deleteItem(i:number) {
      this.itemForms.removeAt(i)
    }
    async submitHandler() {
      this.loading = true;
      const formData = this.existingCustomerCostEstimateForm.value;
      console.warn(formData);
  
      try {
        this.costEstimateService.addExistingCustomerCostEstimate(formData)
        this.success = true;
      } catch(err) {
        console.error(err)
      }
  
      this.loading = false;
      
    }
  
    getSelectProduct(): any{  
      return this.productService.getSelectProducts()
    }

}