import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ContractService } from 'src/app/services/contract.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-edit-contract',
  templateUrl: './edit-contract.component.html',
  styleUrls: ['./edit-contract.component.scss']
})
export class EditContractComponent implements OnInit {

  // Tracking states
  trackingStates: any = ['Warte auf Lieferung Ersatzteile', 'In Reparatur', 'Qualitaetskontrolle', 'Zur Abholung bereit']

  constructor(
    public contractService: ContractService, 
    public dialogRef: MatDialogRef<EditContractComponent>,
    public notificationService: NotificationService
    ) { }

  ngOnInit(): void {
  }
  onClear() {
    this.contractService.editContractForm.reset();
    this.contractService.initializeEditContractForm();
    this.notificationService.success('Customer Data submitted successfully');
  }

  onClose() {
    this.contractService.editContractForm.reset();
    this.dialogRef.close();
  }

  onSubmit() {
    this.contractService.editContract(this.contractService.editContractForm.value);
    this.contractService.editContractForm.reset();
    this.notificationService.success('Contract and Customer data submitted successfully');
    this.onClose();
  }

}
