<h2>List of all Woocommerce Products</h2>
<br>
<i class="fas fa-file-contract"></i>
<table mat-table [dataSource]="woocommerceService.products" class="mat-elevation-z8">

    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

    <!-- order number Column -->
    <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> Product ID &nbsp;</th>
        <td mat-cell *matCellDef="let product"> {{product.id}} &nbsp;&nbsp;</td>
    </ng-container>

    <!-- date Column -->
    <ng-container matColumnDef="slug">
        <th mat-header-cell *matHeaderCellDef> Slug &nbsp;</th>
        <td mat-cell *matCellDef="let product"> {{product.slug}} &nbsp;&nbsp;</td>
    </ng-container>

    <!-- amount Column -->
    <ng-container matColumnDef="price">
        <th mat-header-cell *matHeaderCellDef> Price &nbsp;</th>
        <td mat-cell *matCellDef="let product"> {{product.price}}€&nbsp;&nbsp;</td>
    </ng-container>

    <!-- stock Column -->
    <ng-container matColumnDef="stock">
        <th mat-header-cell *matHeaderCellDef> Stock &nbsp;</th>
        <td mat-cell *matCellDef="let product"> {{product.stock_quantity}}&nbsp;&nbsp;</td>
    </ng-container>

    <!-- store Column -->
    <ng-container matColumnDef="store">
        <th mat-header-cell *matHeaderCellDef> View in store </th>
        <td mat-cell *matCellDef="let product">
            <a href="{{product.permalink}} " target="_blank" mat-raised-button color="link">
                &nbsp; <mat-icon>visibility</mat-icon>&nbsp;Store page
            </a>
        </td>
    </ng-container>

    <!-- action Column -->
    <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef> Manage Product </th>
        <td mat-cell *matCellDef="let product">
            <button mat-button (click)="onEditContract(product)">
                <mat-icon>edit</mat-icon>&nbsp; Edit
            </button>
        </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>