import { Product } from './../../../models/product.model';
import { Component, OnInit } from '@angular/core';
import { ProductService } from './../../../services/product.service';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-product-update-form',
  templateUrl: './product-update-form.component.html',
  styleUrls: ['./product-update-form.component.scss']
})
export class ProductUpdateFormComponent implements OnInit {

  productUpdateForm!: FormGroup;
  id!: any;
  product!: Product;
  netPrice!: number;

  // Form state
  loading = false;
  success = false;


  //icons
  faSubmit=faPaperPlane;


  constructor(public ProductService: ProductService, private fb: FormBuilder, private route: ActivatedRoute , private router: Router) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get("id")
    console.log(this.id)
    console.warn(this.product)
    

    this.productUpdateForm = this.fb.group({
      id: '',
      description: "",
      buyNet: '0',
      buyGross: '0',
      url: '',
      margin: '0',
      price: '',
      isPublic: true,
      stockIs: '1',
      stockTarget: 0
    })
    this.getProductData(this.id);
  }
  
  async submitHandler() {
    this.loading = true;
    const formData = this.productUpdateForm.value;
    console.log(formData);

    try {
      this.ProductService.putProduct(this.id, formData)
      this.success = true;
      this.router.navigate(['products']);
    } catch (err) {
      console.error(err)
    }

    this.loading = false;

  }

  getProductData(id: string){
    this.ProductService.getProduct(this.id).subscribe(
      data => {
        this.product = data;
        this.productUpdateForm.patchValue({
          id: this.product.id,
          description: this.product.description,
          buyNet: this.product.buyNet,
          buyGross: this.product.buyGross,
          url: this.product.url,
          margin: this.product.margin,
          price: this.product.price,
          isPublic: this.product.isPublic,
          stockIs: this.product.stockIs,
          stockTarget: this.product.stockTarget,
        }
        );
      }
      );
  }

}
