import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { LoginService } from 'src/app/services/login.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {

  constructor(public loginService: LoginService, public dialogRef: MatDialogRef<AddUserComponent>, public notificationService: NotificationService) { }

  ngOnInit(): void {
  }

  onClear() {
    this.loginService.addNewUserForm.reset();
    this.loginService.initializeAddNewUserForm();
    this.notificationService.success('Added new user - Password will be sent via email');
  }

  onClose() {
    this.loginService.addNewUserForm.reset();
    this.loginService.initializeAddNewUserForm();
    this.dialogRef.close();
  }

  onAddNewUser() {
    this.loginService.addUser(this.loginService.addNewUserForm.value);
    this.loginService.addNewUserForm.reset();
    this.loginService.initializeAddNewUserForm();
    this.notificationService.success('User has been created - Password send via email');
    this.onClose();
  }

}
