import { CostEstimateService } from './../../../services/cost-estimate.service';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from 'src/app/services/notification.service';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ProductService } from 'src/app/services/product.service';

@Component({
  selector: 'app-new-customer-cost-estimate',
  templateUrl: './new-customer-cost-estimate.component.html',
  styleUrls: ['./new-customer-cost-estimate.component.scss']
})
export class NewCustomerCostEstimateComponent implements OnInit {
  newCustomerCostEstimateForm!: FormGroup;
  products$: any;

  // Form state
  loading = false;
  success = false;

  constructor(
    public costEstimateService: CostEstimateService,
    public productService: ProductService,
    public notificationService: NotificationService,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.newCustomerCostEstimateForm = this.fb.group({
      firstName: '',
      lastName: '',
      additionalInfo: '',
      street: '',
      streetNumber: '',
      postalCode: '',
      city: '',
      country: 'Deutschland',
      email: '',
      telephoneNumber: '',
      items: this.fb.array([])
    })

    this.products$ = this.getSelectProduct();


  }
  get itemForms() {
    return this.newCustomerCostEstimateForm.get('items') as FormArray
  }

  addItem() {
    const item = this.fb.group({
      id: [],
      amount: []
    })

    this.itemForms.push(item);
  }

  deleteItem(i: number) {
    this.itemForms.removeAt(i)
  }
  async submitHandler() {
    this.loading = true;
    const formData = this.newCustomerCostEstimateForm.value;
    console.warn(formData);

    try {
      this.costEstimateService.addNewCustomerCostEstimate(formData)
      this.success = true;
    } catch (err) {
      console.error(err)
    }

    this.loading = false;

  }

  getSelectProduct(): any {
    return this.productService.getSelectProducts()
  }

}
