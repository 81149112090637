import { AuthService } from './../../services/auth.service';
import { AddUserComponent } from './../login/add-user/add-user.component';
import { NavComponent } from './../../nav/nav.component';
import { SettingsService } from './../../services/settings.service';
import { Component, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/services/notification.service';
import { LoginService } from 'src/app/services/login.service';
import { MatDialog } from '@angular/material/dialog';
import { InvoiceService } from 'src/app/services/invoice.service';
import { IncomingInvoiceService } from 'src/app/services/incoming-invoice.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  constructor(
    public settingsService: SettingsService,
    public notificationService: NotificationService,
    public nav: NavComponent,
    public loginService: LoginService,
    private dialog: MatDialog,
    public authService: AuthService,
    public invoiceService: InvoiceService,
    public incomingInvoiceService: IncomingInvoiceService
  ) { }

  ngOnInit(): void {
    this.settingsService.initializePlacatelForm();
  }

  onSubmitPlacetel() {
    this.settingsService.setPlacetelApiKey(this.settingsService.placetelForm.value);
    this.settingsService.placetelForm.reset();
    this.settingsService.initializePlacatelForm();
    this.notificationService.success('API KEY submitted successfully');
  }

  onSubmitWoocommerce() {
    this.settingsService.setWoocommerceApiKeys(this.settingsService.woocommerceForm.value);
    this.settingsService.woocommerceForm.reset();
    this.settingsService.initializeWoocommerceForm();
    this.notificationService.success('Woocommerce API Keys submitted successfully');
  }

  onSubmitNewLogo() {
    this.settingsService.updateLogo(this.settingsService.logoForm.value);
    this.settingsService.logoForm.reset();
    this.settingsService.initializeLogoForm();
    this.notificationService.success('Logo Image updated');
  }

  openAddNewUser() {
    this.loginService.initializeAddNewUserForm();
    this.dialog.open(AddUserComponent);
  }

  backupDatabase(){
    this.settingsService.backupDatabase();
  }
  downloadInvoices(start: string, end: string){
    if(start == "" || end == "" || start == null || end == null){
      console.log("start or end date is empty")
      return;
    }
    this.invoiceService.downloadInvoiceByDate(start,end).subscribe(data => {
      const blob = new Blob([data], {
        type: 'application/zip'
      });
      const url = window.URL.createObjectURL(blob);
      window.open(url);
    });
  }

  downloadExpenseReports(start: string, end: string){
    if(start == "" || end == "" || start == null || end == null){
      console.log("start or end date is empty")
      return;
    }
    this.incomingInvoiceService.downloadExpensesByDate(start,end).subscribe(data => {
      const blob = new Blob([data], {
        type: 'application/zip'
      });
      const url = window.URL.createObjectURL(blob);
      window.open(url);
    });
  }
  

  getZippedInvoices(data: any) {
    const blob = new Blob([data['_body']], { type: 'application/zip' });

    const a: any = document.createElement('a');
    document.body.appendChild(a);

    a.style = 'display: none';    
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = "invoices.zip";
    a.click();
    window.URL.revokeObjectURL(url);
  }
}