import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ebay-sales',
  templateUrl: './ebay-sales.component.html',
  styleUrls: ['./ebay-sales.component.scss']
})
export class EbaySalesComponent implements OnInit {

  token!: string

  constructor() { }

  ngOnInit(): void {
    this.token = (localStorage.getItem('ebayToken') || '').toString()
    console.log("ebay sales component with token: " + this.token)

  }

}
