import { DateService } from 'src/app/services/date.service';
import { InvoiceService } from './../../../services/invoice.service';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { faMinus, faPaperPlane, faPlus } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute } from '@angular/router';
import { ProductService } from 'src/app/services/product.service';

@Component({
  selector: 'app-existing-customer-invoice',
  templateUrl: './existing-customer-invoice.component.html',
  styleUrls: ['./existing-customer-invoice.component.scss']
})
export class ExistingCustomerInvoiceComponent implements OnInit {

  existingCustomerInvoiceForm!: FormGroup;
  id!: any;
  products$: any;

  //icons
  faPlus = faPlus;
  faMinus = faMinus;
  faSubmit = faPaperPlane;

  // Form state
  loading = false;
  success = false;

  constructor(public InvoiceService: InvoiceService, private fb: FormBuilder, private route: ActivatedRoute, public dateService: DateService, public productService: ProductService) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get("id")
    this.existingCustomerInvoiceForm = this.fb.group({
      customer: this.id,
      invoiceDate: this.dateService.getDate(),
      performanceDate: this.dateService.getDate(),
      discount: 0,
      shippingCost: 0,
      isPaid: true,
      sendMail: true,
      warranty: 'Normal',
      items: this.fb.array([])
    })
    this.products$ = this.getSelectProduct();
  }
  get itemForms() {
    return this.existingCustomerInvoiceForm.get('items') as FormArray
  }

  addItem() {
    const item = this.fb.group({
      id: [],
      amount: [1],
      diffPrice: []
    })

    this.itemForms.push(item);
  }

  deleteItem(i: number) {
    this.itemForms.removeAt(i)
  }
  async submitHandler() {
    this.loading = true;
    const formData = this.existingCustomerInvoiceForm.value;
    console.warn(formData);

    try {
      this.InvoiceService.postExistingCustomerInvoice(formData)
      this.success = true;
    } catch (err) {
      console.error(err)
    }

    this.loading = false;

  }
  getSelectProduct(): any{  
    return this.productService.getSelectProducts()
  }

}
