<mat-toolbar>
    <span>Create Customer</span>
    <span class="fill-remaining-space"></span>
    <button class="btn-dialog-close" mat-stroked-button (click)="onClose()" tabIndex="-1">
        <mat-icon>clear</mat-icon>
    </button>
</mat-toolbar>
<mat-dialog-content>
    <form [formGroup]="CustomerService.form" (ngSubmit)="onSubmit()">
        <br>
        <mat-form-field>
            <input matInput placeholder="First Name" formControlName="firstName">
        </mat-form-field>
        <br>
        <mat-form-field>
            <input matInput placeholder="Last Name" formControlName="lastName">
        </mat-form-field>
        <br>
        <mat-form-field>
            <input matInput placeholder="Street" formControlName="street">
        </mat-form-field>
        <br>
        <mat-form-field>
            <input matInput placeholder="Street Number" formControlName="streetNumber">
        </mat-form-field>
        <br>
        <mat-form-field>
            <input matInput placeholder="Additional Information" formControlName="additionalInfo">
        </mat-form-field>
        <br>
        <mat-form-field>
            <input matInput placeholder="Postal Code" formControlName="postalCode">
        </mat-form-field>
        <br>
        <mat-form-field>
            <input matInput placeholder="City" formControlName="city">
        </mat-form-field>
        <br>
        <mat-form-field>
            <input matInput placeholder="Country" formControlName="country">
        </mat-form-field>
        <br>
        <mat-form-field>
            <input matInput placeholder="Email" formControlName="email">
        </mat-form-field>
        <br>
        <mat-form-field>
            <input matInput placeholder="Phone Number" formControlName="telephoneNumber">
        </mat-form-field>
        <br>

        <button mat-raised-button color="primary" type="submit" [disabled]="CustomerService.form.invalid">
            <mat-icon>send</mat-icon>&nbsp; Submit Customer
        </button>
    </form>
</mat-dialog-content>